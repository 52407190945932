import ListActionTypes from './list.types';

export const getListStart = (list) => ({
  type: ListActionTypes.GET_LIST_START,
  payload: list
});

export const getlistSuccess = (list) => ({
  type: ListActionTypes.GET_LIST_SUCCESS,
  payload: list
});

export const getlistError = (list) => ({
  type: ListActionTypes.GET_LIST_ERROR,
  payload: list
});

export const listServerError = (list) => ({
  type: ListActionTypes.LIST_SERVER_ERROR,
  payload: list
});

export const setVdoUrl = (value) => ({
  type: ListActionTypes.SET_VDO_URL,
  payload: value
});

export const setWebinerItem = (value) => ({
  type: ListActionTypes.SET_WEBINER_ITEMS,
  payload: value
});

export const setId = (value) => ({
  type: ListActionTypes.SET_WEBINER_ID,
  payload: value
});

export const getStoreDataStart = (list) => ({
  type: ListActionTypes.GET_STORE_DATA_START,
  payload: list
});

export const updateMerchantDetails = (list) => ({
  type: ListActionTypes.SET_MERCHANT_DETAIL,
  payload: list
});

export const updateProductIdForVideo = (id) => ({
  type: ListActionTypes.SET_VIDEO_PRODUCT_ID,
  payload: id
});

export const setBuNowObjectData = (item) => ({
  type: ListActionTypes.BUY_NOW_ITEM_DATA_SET,
  payload: item
});
