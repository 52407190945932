/* eslint-disable import/no-cycle */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import ListActionTypes from './list.types';
import { API_LIWESHOWS_LIST, API_GET_STORE_RELATED_DATA } from '../../utils/consts/api';
import {
  getlistSuccess,
  getlistError,
  listServerError,
  updateMerchantDetails
} from './list.actions';
import axios from '../../utils/config/http';

const getListApi = async () => {
  try {
    const res = await axios.get(API_LIWESHOWS_LIST);
    return res.data;
  } catch (err) {
    return [];
  }
};

const getStoreDetailListApi = async (store) => {
  try {
    const res = await axios.get(API_GET_STORE_RELATED_DATA + store);
    return res.data;
  } catch (err) {
    return [];
  }
};

export function* getLiweShowsDetailList({ payload }) {
  try {
    const response = yield call(getListApi, payload);
    console.log('responseCode', response);
    if (response.responseCode === 0) {
      if (response.response.upcoming && response.response.upcoming.length > 0) {
        const obj = {
          merchantId: response.response.upcoming[0].merchantId,
          merchantImage: response.response.upcoming[0].merchantImage,
          merchantName: response.response.upcoming[0].merchantName,
          merchantStoreImage: response.response.upcoming[0].merchantStoreImage,
          merchantStoreName: response.response.upcoming[0].merchantStoreName,
          webinarId: response.response.upcoming[0].webinarId
        };
        yield put(updateMerchantDetails(obj));
      } else if (response.response.today && response.response.today.length > 0) {
        const obj = {
          merchantId: response.response.today[0].merchantId,
          merchantImage: response.response.today[0].merchantImage,
          merchantName: response.response.today[0].merchantName,
          merchantStoreImage: response.response.today[0].merchantStoreImage,
          merchantStoreName: response.response.today[0].merchantStoreName,
          webinarId: response.response.today[0].webinarId
        };
        yield put(updateMerchantDetails(obj));
      } else if (response.response.vod && response.response.vod.length > 0) {
        const obj = {
          merchantId: response.response.vod[0].merchantId,
          merchantImage: response.response.vod[0].merchantImage,
          merchantName: response.response.vod[0].merchantName,
          merchantStoreImage: response.response.vod[0].merchantStoreImage,
          merchantStoreName: response.response.vod[0].merchantStoreName,
          webinarId: response.response.vod[0].webinarId
        };
        yield put(updateMerchantDetails(obj));
      } else {
        const obj = {
          merchantId: null,
          merchantImage: null,
          merchantName: null,
          merchantStoreImage: null,
          merchantStoreName: null,
          webinarId: null
        };
        yield put(updateMerchantDetails(obj));
      }
      yield put(getlistSuccess(response));
    } else {
      yield put(getlistError(response));
    }
  } catch (error) {
    yield put(listServerError(error));
  }
}

export function* getStoreLiweshowDetails({ payload }) {
  try {
    const response = yield call(getStoreDetailListApi, payload);
    if (response.responseCode === 0) {
      if (response.response.upcoming && response.response.upcoming.length > 0) {
        const obj = {
          merchantId: response.response.upcoming[0].merchantId,
          merchantImage: response.response.upcoming[0].merchantImage,
          merchantName: response.response.upcoming[0].merchantName,
          merchantStoreImage: response.response.upcoming[0].merchantStoreImage,
          merchantStoreName: response.response.upcoming[0].merchantStoreName,
          webinarId: response.response.upcoming[0].webinarId
        };
        yield put(updateMerchantDetails(obj));
      } else if (response.response.today && response.response.today.length > 0) {
        const obj = {
          merchantId: response.response.today[0].merchantId,
          merchantImage: response.response.today[0].merchantImage,
          merchantName: response.response.today[0].merchantName,
          merchantStoreImage: response.response.today[0].merchantStoreImage,
          merchantStoreName: response.response.today[0].merchantStoreName,
          webinarId: response.response.today[0].webinarId
        };
        yield put(updateMerchantDetails(obj));
      } else if (response.response.vod && response.response.vod.length > 0) {
        const obj = {
          merchantId: response.response.vod[0].merchantId,
          merchantImage: response.response.vod[0].merchantImage,
          merchantName: response.response.vod[0].merchantName,
          merchantStoreImage: response.response.vod[0].merchantStoreImage,
          merchantStoreName: response.response.vod[0].merchantStoreName,
          webinarId: response.response.vod[0].webinarId
        };
        yield put(updateMerchantDetails(obj));
      } else {
        const obj = {
          merchantId: null,
          merchantImage: null,
          merchantName: null,
          merchantStoreImage: null,
          merchantStoreName: null,
          webinarId: null
        };
        yield put(updateMerchantDetails(obj));
      }
      yield put(getlistSuccess(response));
    } else {
      yield put(getlistError(response));
    }
  } catch (error) {
    yield put(listServerError(error));
  }
}

export function* getLiweshowsList() {
  yield takeLatest(ListActionTypes.GET_LIST_START, getLiweShowsDetailList);
}

export function* getStoreRelatedData() {
  yield takeLatest(ListActionTypes.GET_STORE_DATA_START, getStoreLiweshowDetails);
}

export function* listSagas() {
  yield all([
    call(getLiweshowsList),
    call(getStoreRelatedData)
  ]);
}
