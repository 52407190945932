import React, { useState } from 'react';
import { AiFillGoogleCircle, AiOutlineArrowLeft } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
// import { SiFacebook } from 'react-icons/si';
import LoginComponent from '../login-component';
import RedgComponent from '../redg-component';
import { googleSignInStart } from '../../redux/user/user.actions';
import facebook from '../../assets/images/facebook.png';
import styles from './auth.module.scss';

const AuthComponent = ({ showProductDetails }) => {
  const dispatch = useDispatch();
  // const [isLoggedIn, setOpenToLogin] = useState(false);
  const [openRedg, setopenToRedg] = useState(false);

  const onFailure = (error) => {
    console.log(error);
  };

  const openToRedg = () => {
    setopenToRedg(true);
  };
  const openToLoginFunc = () => {
    setopenToRedg(false);
  };

  const googleResponse = (e) => {
    if (e && e.tokenId) {
      const obj = {
        loginType: 'social',
        facebookId: '',
        name: e.profileObj.name,
        email: e.profileObj.email,
        userName: e.profileObj.email.split('@')[0],
        emailId: e.profileObj.email,
        googleId: e.profileObj.googleId,
        token: e.tokenId
      };
      dispatch(googleSignInStart(obj));
    } else {
      toast.warning('Login Request unsuccessful', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const facebookResponse = (e) => {
    if (e && e.id) {
      const obj = {
        loginType: 'social',
        facebookId: e.id,
        googleId: '',
        name: e.name,
        email: e.email,
        userName: e.email.split('@')[0],
        emailId: e.email,
        token: e.accessToken
      };
      // console.log('google 2', obj);
      dispatch(googleSignInStart(obj));
    } else {
      toast.warning('Login Request unsuccessful.', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // const backToProductDetailsDiv = () => {
  //   setOpenToLogin(false);
  // };

  return (
    <>
      {!openRedg && (
        <div className="row m-0" style={{ padding: '10px 0 10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
            <AiOutlineArrowLeft style={{ cursor: 'pointer' }} onClick={showProductDetails} />
            <h6 className={styles.prodListHeading}>SIGN IN</h6>
            <span> &nbsp; </span>
          </div>
          <LoginComponent />
          <div className={styles.redgLink} onClick={openToRedg}>CREATE ACCOUNT?</div>
          <div className="row m-0">
            <div className="col-12 text-center">
              <h6 className="mb-0" style={{ fontSize: '14px' }}>OR SIGN IN WITH</h6>
              <div className="w-100" style={{ display: 'flex', justifyContent: 'center' }}>
                <GoogleLogin
                  clientId="270966538572-5h4ll27v4oa8ldiknebsg9kmfrj6ql1j.apps.googleusercontent.com"
                  buttonText=""
                  onSuccess={googleResponse}
                  onFailure={onFailure}
                  className={styles.customizeGoogle}
                  cookiePolicy="single_host_origin"
                >
                  <AiFillGoogleCircle />
                </GoogleLogin>
                <FacebookLogin
                  appId="3441642612830453"
                  autoLoad={false}
                  fields="name ,email, picture"
                  scope="public_profile, email"
                  callback={facebookResponse}
                  // cssClass="my-facebook-button-class"
                  // icon={<SiFacebook />}
                  render={(renderProps) => (
                    <button type="button" onClick={renderProps.onClick} className={styles.customizeFacebook}>
                      <img src={facebook} alt="facebook login" width="45px" />
                    </button>
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.authFooter} style={{ marginTop: '10px' }}>
            <Link to="/tandc" target="_blank">Terms of Use</Link>
            <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
          </div>
        </div>
      )}

      {openRedg && (
        <>
          <div className="row m-0" style={{ padding: '5px 0 10px' }}>
            <div className="col-12">
              <AiOutlineArrowLeft style={{ position: 'absolute', left: '10px', top: '10px', zIndex: '999999', cursor: 'pointer' }} onClick={openToLoginFunc} />
              <h6 className={styles.prodListHeading} style={{ marginBottom: '20px' }}>CREATE ACCOUNT</h6>
            </div>
            <RedgComponent />
            <div className={styles.redgLink} style={{ textAlign: 'center' }} onClick={openToLoginFunc}>
              ALREADY HAVE AN ACCOUNT?
              {' '}
              <span style={{ textDecoration: 'underline' }}>SIGN IN</span>
            </div>
          </div>
          <div className={styles.authFooter}>
            <Link to="/tandc" target="_blank">Terms of Use</Link>
            <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
          </div>
        </>
      )}
    </>
  );
};

AuthComponent.propTypes = {
  showProductDetails: PropTypes.func.isRequired
};

export default AuthComponent;
