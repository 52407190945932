import React, { useState, useEffect } from 'react';
import WebinarDesktopComponent from './desktop';
import WebinarMobileComponent from '../../mobile/webinar';

// eslint-disable-next-line no-shadow
const WebinarComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // const { id } = useParams();
  const checkIfMobile = () => {
    const becameMobile = window.innerWidth <= 768;

    if (isMobile !== becameMobile) {
      setIsMobile(becameMobile);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', checkIfMobile);
  }, [isMobile]);

  if (isMobile) {
    return (
      <WebinarMobileComponent />
    );
  }

  return (
    <WebinarDesktopComponent />
  );
};

export default WebinarComponent;
