/* eslint-disable import/no-cycle */
import { all, call } from 'redux-saga/effects';
import { userSagas } from './user/user.sagas';
import { listSagas } from './liweShowsList/list.sagas';
import { cartSagas } from './cart/cart.sagas';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(listSagas),
    call(cartSagas)
  ]);
}
