/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { gapi } from 'gapi-script';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { AiOutlineClose, AiFillGoogleCircle } from 'react-icons/ai';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import styles from './dialogBox.module.scss';
import facebook from '../../assets/images/facebook.png';
import LoginComponent from '../login-component';
import RedgComponent from '../redg-component';
import { googleSignInStart, updateLoggedInError } from '../../redux/user/user.actions';
// import LoaderDivComponent from '../loader-component/div-loader';

const LoginRegisterDialog = ({ open, onDisagree, userId, isLoggedInFailed, errorMessage }) => {
  const dispatch = useDispatch();
  const [openToRedg, setOpenToRedg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setOpenToRedg(false);
  }, '');
  const handleOnClickToRedg = () => {
    setOpenToRedg(true);
  };
  const handleOnClickToLogin = () => {
    setOpenToRedg(false);
  };

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: '270966538572-5h4ll27v4oa8ldiknebsg9kmfrj6ql1j.apps.googleusercontent.com' });
    });
  }, []);

  const googleResponse = (e) => {
    if (e && e.tokenId) {
      setShowLoader(true);
      const obj = {
        loginType: 'social',
        facebookId: '',
        name: e.profileObj.name,
        userName: e.profileObj.email.split('@')[0],
        emailId: e.profileObj.email,
        email: e.profileObj.email,
        googleId: e.profileObj.googleId,
        token: e.tokenId
      };
      dispatch(googleSignInStart(obj));
    } else {
      toast.warning('Login Request unsuccessful', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const facebookResponse = (e) => {
    console.log('facebookResponse', e);
    if (e && e.id) {
      setShowLoader(true);
      const obj = {
        loginType: 'social',
        facebookId: e.id,
        googleId: '',
        name: e.name,
        email: e.email,
        userName: e.email.split('@')[0],
        emailId: e.email,
        token: e.accessToken
      };
      dispatch(googleSignInStart(obj));
    } else {
      toast.warning('Login Request unsuccessful.', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onFailure = (error) => {
    console.log('Error', error);
  };

  useEffect(() => {
    if (isLoggedInFailed && errorMessage && errorMessage.errorMsg) {
      setShowLoader(false);
      updateLoggedInError();
    }
  }, [isLoggedInFailed, errorMessage]);

  console.log('showLoader', showLoader);

  return (
    <>
      {
        !showLoader && (
        <div>
          <Dialog
            open={open && !userId}
            keepMounted
            onClose={onDisagree}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            onBackdropClick="false"
          >
            <DialogTitle id="alert-dialog-title" style={{ padding: '0px 5px 0', textAlign: 'center' }}>
              <h6 className={styles.headText} style={{ display: 'inline-block' }}>{openToRedg ? 'Sign UP' : 'Sign In'}</h6>
              <AiOutlineClose style={{ cursor: 'pointer', float: 'right' }} onClick={onDisagree} />
            </DialogTitle>
            <DialogContent style={{ width: '350px' }}>
              <DialogContentText id="alert-dialog-description">
                {!openToRedg && (
                  <div style={{ width: '100%' }}>
                    <LoginComponent />
                    <div className={styles.redgLink} onClick={() => handleOnClickToRedg()}>CREATE ACCOUNT?</div>
                    <div className="row m-0 mt-5">
                      <div className="col-12 text-center">
                        <h6 className="mb-3" style={{ fontSize: '12px', color: '#424242' }}>OR SIGN IN WITH</h6>
                        <div className="w-100" style={{ display: 'flex', justifyContent: 'center' }}>
                          <GoogleLogin
                            clientId="270966538572-5h4ll27v4oa8ldiknebsg9kmfrj6ql1j.apps.googleusercontent.com"
                            buttonText=""
                            onSuccess={googleResponse}
                            onFailure={onFailure}
                            cookiePolicy="single_host_origin"
                            className={styles.customizeGoogle}
                          >
                            <AiFillGoogleCircle />
                          </GoogleLogin>
                          <FacebookLogin
                            appId="3441642612830453"
                            autoLoad={false}
                            fields="name, email, picture"
                            scope="public_profile, email"
                            callback={facebookResponse}
                            disableMobileRedirect
                            render={(renderProps) => (
                              <button type="button" onClick={renderProps.onClick} className={styles.customizeFacebook}>
                                <img src={facebook} alt="" width="45px" />
                              </button>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {openToRedg && (
                  <div style={{ width: '100%' }}>
                    <RedgComponent />
                    <div className={styles.redgLink} style={{ textAlign: 'center' }} onClick={() => handleOnClickToLogin()}>ALREADY HAVE AN ACCOUNT? SIGN IN</div>
                  </div>
                )}
                <div className={styles.authFooter}>
                  <Link to="/tandc" target="_blank">Terms of Use</Link>
                  <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
        )
      }
    </>
  );
};

LoginRegisterDialog.propTypes = {
  userId: PropTypes.string,
  isLoggedInFailed: PropTypes.bool,
  errorMessage: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userId: state.user.currentUser,
  isLoggedInFailed: state.user.isLoggedInFailed,
  errorMessage: state.user.error
});

export default connect(mapStateToProps, null)(LoginRegisterDialog);
