const ListActionTypes = {
  GET_LIST_START: 'GET_LIST_START',
  GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
  GET_LIST_ERROR: 'GET_LIST_ERROR',
  LIST_SERVER_ERROR: 'LIST_SERVER_ERROR',
  SET_VDO_URL: 'SET_VDO_URL',
  GET_STORE_DATA_START: 'GET_STORE_DATA_START',
  SET_WEBINER_ITEMS: 'SET_WEBINER_ITEMS',
  SET_WEBINER_ID: 'SET_WEBINER_ID',
  SET_MERCHANT_DETAIL: 'SET_MERCHANT_DETAIL',
  SET_VIDEO_PRODUCT_ID: 'SET_VIDEO_PRODUCT_ID',
  BUY_NOW_ITEM_DATA_SET: 'BUY_NOW_ITEM_DATA_SET'
};

export default ListActionTypes;
