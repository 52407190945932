import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { BsPlayCircle } from 'react-icons/bs';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import styles from './productList.module.scss';
import dummy from '../../assets/icons/dummy-image.jpg';
import { ProductHLSVideo } from '../../components/video-player/prod-video';

const IndividualProductDetail = ({ prodDetails, callAddtoCart, buySelectedProduct }) => {
  const [isVideo, setIsVideo] = useState(true);
  const [parameterList, setparameterList] = useState([]);
  const [categoryFields, setCategoryFields] = useState([]);
  const [initialValue, setInitialValue] = useState(0);
  const [selectedCatData, setCategoryData] = useState([]);
  const [priceToDisplay, setPriceToDisplay] = useState(prodDetails.parameterList[0].price);
  // const [selectedSize, setSelectedSize] = useState('');
  const [selectedCategory, setCategoryListSelect] = useState(null);
  // const renderValue = (elementIndex) => {
  //   // prodDetails.commonCategoryFields.forEach((a) => {
  //   defaultValueOfparam.categoryFields.forEach((b) => {
  //     if (elementIndex.categoryFieldId === b.categoryFieldId) {
  //       elementIndex.values.forEach((value) => {
  //         if (value === b.enteredValue[0]) {
  //           return b.enteredValue[0];
  //         }

  //         return '';
  //       });
  //     }

  //     return '';
  //   });
  //   // });
  // };

  const [showPlayControl, setPlayControl] = useState(true);
  useEffect(() => {
    const subVidId = document.getElementById('subVideo');
    if (subVidId) {
      subVidId.onload = () => {
        // console.log('video found');
        setIsVideo(true);
      };

      subVidId.onerror = () => {
        // console.log('error in loading video');
        setIsVideo(false);
      };
    }
    if (subVidId && subVidId.paused) {
      setPlayControl(true);
    }
  }, [showPlayControl]);

  useEffect(() => {
    if (prodDetails && prodDetails.parameterList.length > 0) {
      prodDetails.parameterList.forEach((params) => {
        for (let i = 0; i < params.categoryFields.length; i++) {
          if (params.categoryFields[i].categoryFieldId === prodDetails.categoryFields[i].categoryFieldId) {
            const a = prodDetails.categoryFields[i].selectedValue || [];
            if (!a.includes(params.categoryFields[i].enteredValue.toString())) {
              a.push(params.categoryFields[i].enteredValue.toString());
              // prodDetails.categoryFields[i].selectedValue += params.categoryFields[i].enteredValue;
              prodDetails.categoryFields[i].selectedValue = a;
            }
          }
        }
      });
      // console.log(prodDetails.categoryFields);
      setCategoryFields(prodDetails.categoryFields);
      if (prodDetails.categoryFields.length === 0) {
        const l = { ...prodDetails.parameterList[0] };
        delete l.categoryFields;
        setCategoryListSelect(l);
      }
    }
  }, []);

  useEffect(() => {
    if (prodDetails && prodDetails.parameterList.length > 0) {
      let p = 0;
      prodDetails.parameterList.forEach((params) => {
        if (p === 0) {
          params.categoryFields.forEach((item) => {
            item.active = true;
          });
          p++;
        }
      });
      // console.log(prodDetails.categoryFields);
      setparameterList(prodDetails.parameterList);
    }
  }, []);

  const comparer = (otherArray) => {
    return (current) => {
      return otherArray.filter((other) => {
        return other.categoryFieldId === current.categoryFieldId && other.enteredValue.toString() === current.enteredValue.toString();
      }).length === 0;
    };
  };

  const checkMatchedCategory = (cata, catb) => {
    const catc = [];
    cata.forEach((item) => {
      const obj = {
        categoryFieldId: item.categoryFieldId,
        enteredValue: item.enteredValue.toString()
      };
      catc.push(obj);
    });
    // console.log(catc);
    // console.log(catb);
    const onlyInA = catc.filter(comparer(catb));
    const onlyInB = catb.filter(comparer(catc));
    // console.log(onlyInA);
    // console.log(onlyInB);
    const result = onlyInA.concat(onlyInB);

    if (result.length === 0) {
      return true;
    }

    return false;
  };

  const validatePrice = (item, categoryId) => {
    // console.log('item', item);
    // console.log('parameterList', parameterList);
    // setSelectedSize(item);
    const d = [...parameterList];
    let pp = [];
    d.forEach((param) => {
      param.categoryFields.forEach((categ) => {
        if (categ.categoryFieldId === categoryId && categ.enteredValue.toString() === item) {
          categ.active = true;
        } else if (categ.categoryFieldId === categoryId) {
          categ.active = false;
        }
      });
    });
    setparameterList(d);
    pp = [...d];
    const de = [];
    if (pp.length > 0) {
      pp.forEach((dta) => {
        dta.categoryFields.forEach((test) => {
          // console.log('dta', dta);
          if (test.active) {
            const ppl = de.find((lps) => lps.categoryFieldId === test.categoryFieldId);
            if (!ppl) {
              const obj = {
                categoryFieldId: test.categoryFieldId,
                enteredValue: test.enteredValue.toString()
              };
              setPriceToDisplay(dta.price);
              de.push(obj);
            }
          }
        });
      });
      setCategoryData(de);
    }
    setTimeout(() => {
      if (pp.length > 0) {
        for (let m = 0; m < pp.length; m++) {
          const isMatched = checkMatchedCategory(pp[m].categoryFields, de);
          if (isMatched) {
            const ppd = { ...pp[m] };
            delete ppd.categoryFields;
            setCategoryListSelect(ppd);
            break;
          } else {
            setCategoryListSelect(null);
          }
        }
        // eslint-disable-next-line consistent-return
        // pp.forEach((params) => {
        //   const isMatched = checkMatchedCategory(params.categoryFields, de);
        //   if (isMatched) {
        //     console.log('yes finally i got it');
        //     const ppd = { ...params };
        //     delete ppd.categoryFields;
        //     setCategoryListSelect(ppd);
        //     return false;
        //   }

        //   console.log('No still i am fucking around');
        //   setCategoryListSelect([]);
        // const cata = params.categoryFields;
        // const catb = selectedCatData;
        // const isSameUser = (a, b) => a.categoryFieldId === b.categoryFieldId && a.enteredValue.toString() === b.enteredValue;
        // // const catl = params.categoryFields;
        // // let lm = 1;
        // // for (let k = 0; k < de.length; k++) {
        // //   // console.log(catl[k]);
        // //   if (catl[k].categoryFieldId === de[k].categoryFieldId && catl[k].enteredValue.toString() === de[k].enteredValue) {
        // //     console.log(de[k]);
        // //     lm = 1;
        // //     break;
        // //   } else {
        // //     console.log('------------------------------');
        // //     lm = 2;
        // //   }
        // // }
        // // if (lm === 1) {
        // //   const ppd = { ...params };
        // //   delete ppd.categoryFields;
        // //   setCategoryListSelect(ppd);
        // // } else {
        // //   setCategoryListSelect(null);
        // // }
        // });
      }
    }, 500);
  };

  // const isActivebutton = (category, categoryId) => {
  //   console.log(category);
  //   if (parameterList.length > 0) {
  //     const d = parameterList.filter((item) => (item.categoryFields.some((bd) => bd.categoryFieldId === categoryId && bd.active && bd.enteredValue.toString() === category)));
  //     console.log(d);
  //     if (d && d.length > 0) {
  //       return true;
  //     }

  //     return false;
  //   }

  //   return false;
  // };

  // 2dc00461-577e-45fd-8d64-1651d8f092f0
  // 2dc00461-577e-45fd-8d64-1651d8f092f0

  const getUniqeArray = (item, categoryId) => {
    // console.log('console here', item);
    // console.log('console here', categoryId);
    // console.log('console here', parameterList);
    const d = [...new Set(item)];
    // console.log('console here', d);
    let isbtnActive = false;
    let de = [];
    if (parameterList.length > 0) {
      de = parameterList.filter((bitem) => (bitem.categoryFields.some((bd) => bd.categoryFieldId === categoryId && bd.active)));
      // console.log('console here', de);
      if (de) {
        isbtnActive = true;
      } else {
        isbtnActive = false;
      }
      if (initialValue === 0) {
        const l = { ...de[0] };
        delete l.categoryFields;
        const lp = [];
        de[0].categoryFields.forEach((ditem) => {
          const obj = {
            categoryFieldId: ditem.categoryFieldId,
            enteredValue: ditem.enteredValue.toString()
          };
          lp.push(obj);
        });
        setCategoryData(lp);
        setCategoryListSelect(l);
        setInitialValue(1);
      }
    }
    if (de.length > 0) {
      return (
        d.map((pt) => (
          <Button type="button" className={isbtnActive && de[0].categoryFields.find((ls) => ls.enteredValue.toString() === pt) ? `${styles.activebtn}` : ''} onClick={() => validatePrice(pt, categoryId)}>{pt}</Button>
        ))
      );
    }

    return (<></>);
  };

  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const addProductToCart = () => {
    callAddtoCart(selectedCatData);
  };

  const buySelectedProductAction = () => {
    buySelectedProduct(selectedCatData);
  };

  const playSubVideo = () => {
    const subVidId = document.getElementById('subVideo');
    if (subVidId) {
      setPlayControl(false);
      subVidId.play();
    }
  };

  // const pauseSubVideo = () => {
  //   const subVidId = document.getElementById('subVideo');
  //   if (subVidId) {
  //     setPlayControl(true);
  //     subVidId.pause();
  //   }
  // };

  // console.log('selectedCatData', selectedCatData);
  // console.log('selectedCatData2', prodDetails);

  return (
    <>
      <div className="col-12 p-0">
        <div className={styles.productImageVideo}>
          {prodDetails.productVideoList && prodDetails.productVideoList.length > 0 && isVideo && (
            <div className={styles.productVideo}>
              <ProductHLSVideo currentVideo={prodDetails.productVideoList[0]} />
              {showPlayControl && <BsPlayCircle onClick={playSubVideo} />}
              {/* {!showPlayControl && <BsPauseCircle onClick={pauseSubVideo} />} */}
            </div>
          )}
          {prodDetails.productImageList && prodDetails.productImageList.length > 0 && (
          <div className={styles.prodDetailImage}>
            <img className="img-fluid" style={{ borderRadius: '7px' }} src={prodDetails.productImageList[0]} onError={handleImageErr} alt="No Inage" />
          </div>
          )}
        </div>
        {/* <img className={`img-fluid ${styles.prodDetailImage}`} src={prodDetails.productImageList[0]} onError={handleImageErr} alt="No Inage" /> */}
      </div>
      <div className="col-12" style={{ height: '135px', overflowY: 'auto' }}>
        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p className={styles.prodDetailText}>{prodDetails.name}</p>
          <div>
            <p className={styles.prodDetailprice}>
              $
              {' '}
              {priceToDisplay}
              {/* {selectedCategory ? selectedCategory.price : prodDetails.parameterList[0].price} */}
              {/* <span className={styles.prodDetailpriceSpan}> (Incl. taxes)</span> */}
            </p>
          </div>
        </div>
        <div className="col-12">
          <p className={styles.prodDetailDesc}>{prodDetails.description}</p>
        </div>
        {/* <div className="col-12">
          <p className={styles.prodDetailtags}>
            <span style={{ textTransform: 'none', color: 'black' }}>
                &nbsp;
              {prodDetails.tags}
            </span>
          </p>
        </div> */}
        {(prodDetails.commonAttributes.length > 0 || prodDetails.categoryFields.length > 0) && (
          <div className="col-12">
            {prodDetails.commonAttributes.map((element) => (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                {element.type !== 'textView' && (
                <p className={styles.prodDetailtHint}>
                  {element.hint}
                  :
                </p>
                )}
                <p className={styles.prodDetailtHint} style={{ fontWeight: 'normal' }}>{element.enteredValue}</p>
              </div>
            ))}
            {categoryFields.length > 0 && categoryFields.map((element) => {
              // console.log('console here', element);
              return (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column' }}>
                  {element.type && (
                    <>
                      <h4 className={styles.prodDetailtHint}>
                        {element.title}
                        :
                      </h4>
                        {element.selectedValue && element.selectedValue.length > 0 && (
                        <p className={styles.buttonList}>
                          {/* {element.selectedValue.map((listData) => (
                            <Button type="button" className={selectedSize === listData ? `${styles.activebtn}` : ''} onClick={() => validatePrice(listData, element.categoryFieldId)}>{listData}</Button>
                             ))} */}
                          {getUniqeArray(element.selectedValue, element.categoryFieldId)}
                        </p>
                        )}
                    </>
                  )}
                </div>
              );
            })}
            {/* {selectedCategory && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Price Details:</h6>
                <p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1px' }}>
                  <span>Price:</span>
                  <span>{selectedCategory.price}</span>
                </p>
              </div>
            )} */}
          </div>
        )}

        {/* {selectedCatData && selectedCatData.length > 0 && selectedCatData.map((item) => (
          <div>
            {`${item.categoryFieldId} - ${item.enteredValue}` }
          </div>
        ))} */}
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            {/* <div className={styles.customcounterDiv}>
      <AiOutlineMinusCircle style={{ fontSize: '30px', cursor: 'pointer', color: '#646464' }} onClick={() => onClickMinus(count)} />
      <h6 className="m-0" style={{ fontSize: '20px' }}>{count}</h6>
      <AiOutlinePlusCircle style={{ fontSize: '30px', cursor: 'pointer', color: '#646464' }} onClick={() => onClickPlus(count)} />
    </div> */}
            <button type="button" disabled={!selectedCategory} className={`btn ${styles.customButtonBuyNow}`} onClick={buySelectedProductAction}>Buy Now</button>
          </div>
          <div className="col-6">
            <button type="button" disabled={!selectedCategory} className={`btn ${styles.customButtonAddtoCart}`} onClick={addProductToCart}>Add to Cart</button>
          </div>
        </div>
      </div>

    </>
  );
};

IndividualProductDetail.propTypes = {
  // currenttime: PropTypes.number,
  prodDetails: PropTypes.object,
  callAddtoCart: PropTypes.func.isRequired,
  buySelectedProduct: PropTypes.func
};

export default IndividualProductDetail;
