import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AiOutlineDelete, AiTwotoneEdit, AiOutlineArrowLeft } from 'react-icons/ai';
import axios from 'axios';
import { Button } from '@material-ui/core';
import styles from './address.module.scss';
import { DELETE_ADDRESS, ADD_ADDRESS, EDIT_ADDRESS } from '../../utils/consts/api';
import { getCustomerAddress, updateCustomerAddressLocal } from '../../redux/user/user.actions';

// eslint-disable-next-line no-shadow
const AddressComponent1 = ({ allAddress, getCustomerAddress, updateCustomerAddressLocal, token, hideAddressForm }) => {
  const [showAddressForm, setshowAddressForm] = useState(false);
  const [handleeditAddress, seteditAddress] = useState(false);
  const [keepEditAddressData, setkeepEditAddressData] = useState('');
  const [addressList, setAddressList] = useState([]);
  const [addressinputs, setaddressinputs] = useState({
    fullName: '',
    shippingAddressName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    pinCode: '',
    phoneNumber: ''
  });

  useEffect(() => {
    setAddressList(allAddress);
  }, [allAddress]);

  const { fullName, shippingAddressName, addressLine1, addressLine2, city, state, pinCode, phoneNumber } = addressinputs;
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setaddressinputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addNewAddress = () => {
    setshowAddressForm(true);
  };

  const closeOpenAddressForm = () => {
    setshowAddressForm(false);
    hideAddressForm(addressList);
    setaddressinputs({
      fullName: '',
      shippingAddressName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      pinCode: '',
      phoneNumber: ''
    });
  };

  const deleteAddress = async (item) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        authorization: token
      };
      const res = await axios.delete(DELETE_ADDRESS + item.addressId, { headers });
      if (res.data.responseCode === 0) {
        console.log('delete address', res.data.response);
        getCustomerAddress();
        seteditAddress(false);
      } else {
        console.log('delete address');
      }
    } catch (err) {
      console.log('delete address');
    }
  };

  const handleSubmitAddress = async (type) => {
    if (type === false) {
      addressinputs.shippingLatitude = '0';
      addressinputs.shippingLongitude = '0';
      try {
        const headers = {
          'Content-Type': 'application/json',
          authorization: token
        };
        const res = await axios.post(ADD_ADDRESS, addressinputs, { headers });
        if (res.data.responseCode === 0) {
          console.log('add address', res.data.response);
          setshowAddressForm(false);
          setaddressinputs({
            fullName: '',
            shippingAddressName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            pinCode: '',
            phoneNumber: ''
          });
          getCustomerAddress();
          // hideAddressForm(addressList);
          seteditAddress(false);
          setkeepEditAddressData('');
        } else {
          console.log('add address');
        }
      } catch (err) {
        console.log('add address');
      }
    } else {
      addressinputs.addressId = keepEditAddressData.addressId;
      addressinputs.shippingLatitude = '0';
      addressinputs.shippingLongitude = '0';
      try {
        const headers = {
          'Content-Type': 'application/json',
          authorization: token
        };
        const res = await axios.put(EDIT_ADDRESS, addressinputs, { headers });
        if (res.data.responseCode === 0) {
          setshowAddressForm(false);
          setaddressinputs({
            fullName: '',
            shippingAddressName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            pinCode: '',
            phoneNumber: ''
          });
          getCustomerAddress();
          seteditAddress(false);
          setkeepEditAddressData('');
        } else {
          console.log('edit address');
        }
      } catch (err) {
        console.log('edit address');
      }
    }
  };

  const onClickhandleEditAddressForm = (element) => {
    console.log('data', element);
    setkeepEditAddressData(element);
    setshowAddressForm(true);
    seteditAddress(true);
    setaddressinputs({
      fullName: element.fullName,
      shippingAddressName: element.shippingAddressName,
      addressLine1: element.addressLine1,
      addressLine2: element.addressLine2,
      city: element.city,
      state: element.state,
      pinCode: element.pinCode,
      phoneNumber: element.phoneNumber
    });
  };

  const handleAddressSelection = (item) => {
    // item.selectedAddressId = item.addressId;
    const p = [...addressList];
    p.forEach((pt) => {
      if (pt.addressId === item.addressId) {
        pt.selectedAddressId = pt.addressId;
      } else {
        pt.selectedAddressId = '';
      }
    });
    setAddressList(p);
    updateCustomerAddressLocal(p);
    closeOpenAddressForm();
  };

  return (
    <div>
      <div className="row m-0">
        <div className="col-12" style={{ borderBottom: '1px solid #ddd', marginTop: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
            <AiOutlineArrowLeft style={{ cursor: 'pointer', marginRight: '20px' }} onClick={() => closeOpenAddressForm()} />
            <p style={{ alignItems: 'center', paddingTop: '10px' }}>
              {addressList.length > 0 && !showAddressForm ? 'Select' : 'Add'}
              {' '}
              Shipping Address
            </p>
            <span />
            <div />
          </div>
          {!showAddressForm && (
            <div className="col-12 p-0" style={{ textAlign: 'right' }}>
              <Button type="button" onClick={addNewAddress} color="primary">Add New Address</Button>
            </div>
          )}
        </div>
        <div className="col-12 py-1 px-2">
          {addressList && addressList.length > 0 && !showAddressForm && addressList.map((element) => (
            <div className={element.addressId === element.selectedAddressId ? `row m-0 py-1 ${styles.selectedRow} ${styles.addressList}` : `row m-0 py-1 ${styles.addressList}`}>
              <div className={`col-11 py-2 px-1 ${styles.addressListDive}`} onClick={() => handleAddressSelection(element)}>
                <div>
                  <h4>
                    {element.fullName ? `${element.fullName}` : ''}
                  </h4>
                  <p>
                    <span style={{ color: '#424242', fontWeight: '900', marginRight: '10px' }}>{element.shippingAddressName ? `${element.shippingAddressName}, ` : ''}</span>
                    {element.addressLine1 ? `${element.addressLine1}, ` : ''}
                    {element.addressLine2 ? `${element.addressLine2}, ` : ''}
                    {element.city ? `${element.city}, ` : ''}
                    {element.pinCode ? `${element.pinCode}, ` : ''}
                    {element.state ? `${element.state}, ` : ''}
                    {element.phoneNumber ? `${element.phoneNumber}` : ''}
                  </p>
                </div>
              </div>
              <div className={`col-1 p-0 ${styles.svgColor}`} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <AiOutlineDelete onClick={() => deleteAddress(element)} style={{ cursor: 'pointer' }} />
                <br />
                <AiTwotoneEdit onClick={() => onClickhandleEditAddressForm(element)} style={{ cursor: 'pointer' }} />
              </div>
            </div>
          ))}
          {(addressList.length === 0 || showAddressForm) && (
          <form autoComplete="off">
            <div className="row mt-3 mx-0">
              <div className="col-12">
                <div className={`form-group ${styles.customFormGroup}`}>
                  <input type="text" className={`form-control ${styles.customFormControl}`} autoComplete="false" placeholder="Full Name" name="fullName" value={fullName} required onChange={handleAddressChange} />
                </div>
              </div>
              <div className="col-12">
                <div className={`form-group ${styles.customFormGroup}`}>
                  <input type="text" className={`form-control ${styles.customFormControl}`} autoComplete="false" placeholder="Address Line 1" name="addressLine1" value={addressLine1} required onChange={handleAddressChange} />
                </div>
              </div>
              <div className="col-12">
                <div className={`form-group ${styles.customFormGroup}`}>
                  <input type="text" className={`form-control ${styles.customFormControl}`} autoComplete="false" placeholder="Address Line 2" name="addressLine2" value={addressLine2} required onChange={handleAddressChange} />
                </div>
              </div>
              <div className="col-6">
                <div className={`form-group ${styles.customFormGroup}`}>
                  <input type="text" className={`form-control ${styles.customFormControl}`} autoComplete="false" placeholder="City" name="city" value={city} required onChange={handleAddressChange} />
                </div>
              </div>
              <div className="col-6">
                <div className={`form-group ${styles.customFormGroup}`}>
                  <input type="text" className={`form-control ${styles.customFormControl}`} autoComplete="false" placeholder="State" name="state" value={state} required onChange={handleAddressChange} />
                </div>
              </div>
              <div className="col-12">
                <div className={`form-group ${styles.customFormGroup}`}>
                  <input type="text" className={`form-control ${styles.customFormControl}`} autoComplete="false" placeholder="Zip Code" name="pinCode" value={pinCode} required onChange={handleAddressChange} />
                </div>
              </div>
              <div className="col-12">
                <div className={`form-group ${styles.customFormGroup}`}>
                  <input type="text" className={`form-control ${styles.customFormControl}`} autoComplete="false" placeholder="Phone Number" name="phoneNumber" value={phoneNumber} required onChange={handleAddressChange} />
                </div>
              </div>
              <div className="col-12">
                <div className={`form-group ${styles.customFormGroup}`}>
                  <input type="text" className={`form-control ${styles.customFormControl}`} autoComplete="false" placeholder="Shipping Address Name" name="shippingAddressName" value={shippingAddressName} required onChange={handleAddressChange} />
                </div>
              </div>
              <div className="col-12 pt-3">
                <button style={{ marginTop: '-16px' }} type="button" className={`btn ${styles.customButton}`} onClick={() => handleSubmitAddress(handleeditAddress)}>ADD ADDRESS</button>
              </div>
              <div className="col-12">
                <button type="button" className={`btn ${styles.customButton}`} style={{ background: '#424242' }} onClick={closeOpenAddressForm}>CANCEL</button>
              </div>
            </div>
          </form>
          )}
        </div>
      </div>
    </div>
  );
};

AddressComponent1.propTypes = {
  getCustomerAddress: PropTypes.func,
  allAddress: PropTypes.array,
  updateCustomerAddressLocal: PropTypes.func,
  token: PropTypes.string,
  hideAddressForm: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  userId: state.user.currentUser,
  allAddress: state.user.addressList
});

export default connect(mapStateToProps, { getCustomerAddress, updateCustomerAddressLocal })(AddressComponent1);
