import ListActionTypes from './list.types';

const INITIAL_STATE = {
  loadInitial: true,
  upComingList: [],
  today: [],
  vod: [],
  shopVideo: [],
  vdoUrl: '',
  productList: [],
  liweShowId: '',
  selectedItem: '',
  errorMerchant: false,
  message: null,
  responseCode: 0,
  merchantStoreName: null,
  merchantImage: null,
  merchantId: null,
  merchantName: null,
  merchantStoreImage: null,
  productVideoId: null,
  buyNowProductObject: null
};

const listReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ListActionTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        loadInitial: false,
        upComingList: action.payload.response.upcoming,
        today: action.payload.response.today,
        shopVideo: action.payload.response.shopVideo,
        vod: action.payload.response.vod,
        message: action.payload.successMsg,
        responseCode: 0,
        errorMerchant: false,
        buyNowProductObject: null
      };
    case ListActionTypes.SET_MERCHANT_DETAIL:
      return {
        ...state,
        merchantImage: action.payload.merchantImage,
        merchantId: action.payload.merchantId,
        merchantName: action.payload.merchantName,
        merchantStoreImage: action.payload.merchantStoreImage,
        merchantStoreName: action.payload.merchantStoreName,
        liweShowId: action.payload.webinarId
      };
    case ListActionTypes.SET_WEBINER_ID:
      return {
        ...state,
        liweShowId: action.payload.webinarId
      };
    case ListActionTypes.SET_VDO_URL:
      return {
        ...state,
        vdoUrl: action.payload
      };
    case ListActionTypes.SET_VIDEO_PRODUCT_ID:
      return {
        ...state,
        productVideoId: action.payload
      };
    case ListActionTypes.SET_WEBINER_ITEMS:
      return {
        ...state,
        liweShowId: action.payload.webinarId,
        productList: action.payload.productDetails,
        selectedItem: action.payload
      };
    case ListActionTypes.GET_LIST_ERROR:
      return {
        ...state,
        loadInitial: false,
        upComingList: [],
        today: [],
        shopVideo: [],
        vod: [],
        vdoUrl: '',
        productList: [],
        liweShowId: '',
        selectedItem: '',
        merchantStoreName: null,
        merchantImage: null,
        merchantId: null,
        merchantName: null,
        merchantStoreImage: null,
        responseCode: action.payload.responseCode,
        message: action.payload.errorMsg,
        errorMerchant: true,
        buyNowProductObject: null
      };
    case ListActionTypes.LIST_SERVER_ERROR:
      return {
        ...state,
        loadInitial: false,
        upComingList: [],
        today: [],
        shopVideo: [],
        vod: [],
        vdoUrl: '',
        productList: [],
        liweShowId: '',
        selectedItem: '',
        responseCode: 2,
        merchantStoreName: null,
        merchantImage: null,
        merchantId: null,
        merchantName: null,
        merchantStoreImage: null,
        message: 'Internal Server Error!',
        errorMerchant: true,
      };
    case ListActionTypes.BUY_NOW_ITEM_DATA_SET:
      return {
        ...state,
        buyNowProductObject: action.payload
      };
    default:
      return state;
  }
};

export default listReducer;
