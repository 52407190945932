import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './mobile-layout.module.scss';

const MobileLayoutComponent = () => {
  window.addEventListener('resize', () => {
    // We execute the same script as before
    const vh1 = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh1}px`);
  });

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className={`col-12 p-0 ${styles.mobileSection}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MobileLayoutComponent;
