import CartActionTypes from './cart.types';
import { removeItemFromCart } from './cart.utils';

const INITIAL_STATE = {
  hidden: true,
  cartItems: [],
  isCartAdded: false
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.TOGGLE_CART_HIDDEN:
      return {
        ...state,
        hidden: !state.hidden
      };
    case CartActionTypes.GET_CART_ITEM_SUCCESS:
      return {
        ...state,
        cartItems: action.payload.response.cartDetails
      };
    case CartActionTypes.REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeItemFromCart(state.cartItems, action.payload)
      };
    case CartActionTypes.CLEAR_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem.id !== action.payload.id
        )
      };
    case CartActionTypes.CLEAR_CART:
      return {
        ...state,
        cartItems: [],
        isCartAdded: false
      };
    case CartActionTypes.ADD_ITEM_CART_SUCCESS:
      return {
        ...state,
        isCartAdded: true
      };
    case CartActionTypes.ADD_ITEM_CART_FAILURE:
      return {
        ...state,
        isCartAdded: false
      };
    default:
      return state;
  }
};

export default cartReducer;
