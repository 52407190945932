const CartActionTypes = {
  TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
  ADD_CART_ITEM: 'ADD_CART_ITEM',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  CLEAR_ITEM_FROM_CART: 'CLEAR_ITEM_FROM_CART',
  CLEAR_CART: 'CLEAR_CART',
  DELETE_CART_ITEM: 'DELETE_CART_ITEM',
  GET_CART_ITEMS: 'GET_CART_ITEMS',
  GET_CART_ITEM_SUCCESS: 'GET_CART_ITEM_SUCCESS',
  GET_CART_ITEM_FAILURE: 'GET_CART_ITEM_FAILURE',
  INCREASE_CART_QUANTITY: 'INCREASE_CART_QUANTITY',
  REDUCE_CART_QUANTITY: 'REDUCE_CART_QUANTITY',
  ADD_ITEM_CART_SUCCESS: 'ADD_ITEM_CART_SUCCESS',
  ADD_ITEM_CART_FAILURE: 'ADD_ITEM_CART_FAILURE',
  REMOVE_ITEM_CART_SUCCESS: 'REMOVE_ITEM_CART_SUCCESS',
  REMOVE_ITEM_CART_FAILURE: 'REMOVE_ITEM_CART_FAILURE',
  CHANGE_ITEM_CART_SUCCESS: 'CHANGE_ITEM_CART_SUCCESS',
  CHANGE_ITEM_CART_FAILURE: 'CHANGE_ITEM_CART_FAILURE'
};

export default CartActionTypes;
