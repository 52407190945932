import React from 'react';
import { LoaderSection } from '../styled/Sections.styled';
import loaderImg from '../../assets/images/loader.svg';
import botiga from '../../assets/images/Horizontal-Logo-Small.png';

const LoaderComponent = () => {
  return (
    <LoaderSection>
      <div>
        <img src={botiga} alt="loaderimage" />
        <br />
        <img src={loaderImg} alt="loaderimage" />
      </div>
    </LoaderSection>
  );
};

export default LoaderComponent;
