import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SocketProvider } from './components/socket/Socket';
import 'react-toastify/dist/ReactToastify.css';
import routes from './routing/index';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <SocketProvider>
      {routing}
      <ToastContainer autoClose={4000} />
    </SocketProvider>
  );
};

export default App;
