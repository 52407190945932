import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import listReducer from './liweShowsList/list.reducer';
import cartReducer from './cart/cart.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'cart']
};

const rootReducer = combineReducers({
  user: userReducer,
  list: listReducer,
  cart: cartReducer,
});

export default persistReducer(persistConfig, rootReducer);
