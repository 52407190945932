import React from 'react';
import loaderImg from '../../assets/images/loader.svg';

const LoaderTransparentComponent = () => {
  return (
    <div style={{
      height: '100vh',
      width: '100%',
      position: 'absolute',
      zIndex: '9999',
      background: 'transparent',
      top: '0',
      left: '0', }}
    >
      <div style={{ position: 'absolute', top: '5%', left: '20%' }}>
        <img src={loaderImg} alt="loaderimage" />
      </div>
    </div>
  );
};
export default LoaderTransparentComponent;
