import UserActionTypes from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  userType: null,
  token: null,
  error: null,
  success: null,
  userEmail: null,
  userName: null,
  userStatus: -1,
  isRedgValidate: -1,
  responseCode: '',
  valid: '',
  message: '',
  addressList: [],
  stripeCustomerId: '',
  isLoggedInFailed: false
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: '',
        userType: '',
        token: action.payload.response.token,
        success: action.payload.response,
        userEmail: '',
        userStatus: action.payload.response.registrationStatus,
        error: null,
        isRedgValidate: -1,
        responseCode: '',
        valid: '',
        message: '',
        isLoggedInFailed: false
      };
    case UserActionTypes.GOOGLE_SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: '',
        userType: '',
        token: action.payload.response.token,
        success: action.payload.response,
        userEmail: '',
        userStatus: action.payload.response.newRegistration,
        error: null,
        isRedgValidate: -1,
        responseCode: '',
        valid: '',
        message: '',
        isLoggedInFailed: false
      };
    case UserActionTypes.EMAIL_SIGN_UP_SUCCESS:
      return {
        ...state,
        currentUser: null,
        success: action.payload.response,
        userType: '',
        isRedgValidate: 0,
        userStatus: -1,
        error: null,
        responseCode: '',
        valid: '',
        message: '',
        isLoggedInFailed: false
      };
    case UserActionTypes.GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.response.customerId,
        success: action.payload.response,
        userEmail: action.payload.response.emailId,
        userName: action.payload.response.userName,
        stripeCustomerId: action.payload.response.stripeCustomerId
      };
    case UserActionTypes.VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.response.customerId,
        userType: '',
        token: action.payload.response.token,
        success: action.payload.response,
        userEmail: action.payload.response.emailId,
        userStatus: action.payload.response.customerStatus,
        userName: action.payload.response.userName,
        error: null,
        isRedgValidate: -1,
        responseCode: '',
        valid: '',
        message: '',
        isLoggedInFailed: false
      };
    case UserActionTypes.VALIDATE_OTP_START:
      return {
        ...state,
        currentUser: null,
        error: null,
        userType: null,
        token: null,
        userEmail: null,
        success: null,
        isRedgValidate: 0,
        userStatus: -1,
        responseCode: '',
        valid: '',
        message: ''
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
    case UserActionTypes.EMAIL_SIGN_UP_START:
    case UserActionTypes.EMAIL_SIGN_IN_START:
    case UserActionTypes.GOOGLE_SIGN_IN_START:
    case UserActionTypes.CLEAR_CURRENT_STATE:
      return {
        ...state,
        currentUser: null,
        error: null,
        userType: null,
        token: null,
        userEmail: null,
        success: null,
        isRedgValidate: -1,
        userStatus: -1,
        responseCode: '',
        valid: '',
        message: '',
        addressList: [],
        stripeCustomerId: ''
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.EMAIL_SIGN_UP_FAILURE:
    case UserActionTypes.VALIDATE_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
        userType: null,
        token: null,
        success: null,
        currentUser: null,
        userEmail: null,
        userStatus: -1,
        isRedgValidate: -1,
        responseCode: '',
        valid: '',
        message: '',
        isLoggedInFailed: true
      };
    case UserActionTypes.CLEAR_USER_NAME_MESSAGES:
      return {
        ...state,
        currentUser: null,
        userType: null,
        token: null,
        error: null,
        success: null,
        userEmail: null,
        userStatus: -1,
        isRedgValidate: -1,
        responseCode: '',
        valid: '',
        message: ''
      };
    case UserActionTypes.CREATE_USER_NAME_SUCCESS:
      return {
        ...state,
        message: action.payload.data.successMsg,
        responseCode: 0,
        valid: true,
      };
    case UserActionTypes.CREATE_USER_NAME_FAILURE:
      return {
        ...state,
        responseCode: action.payload.data.responseCode,
        message: action.payload.data.errorMsg,
        valid: false,
      };
    case UserActionTypes.CREATE_USER_NAME_SERVER_ERROR:
      return {
        ...state
      };
    case UserActionTypes.GET_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        addressList: action.payload.response.addressList
      };
    case UserActionTypes.UPDATE_CUSTOMER_ADDRESS_LOCAL:
      return {
        ...state,
        addressList: action.payload
      };
    case UserActionTypes.UPDATE_STRIPE_CUSTOMERID:
      return {
        ...state,
        stripeCustomerId: action.payload
      };
    case UserActionTypes.UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        isLoggedInFailed: false,
        error: null
      };
    default:
      return state;
  }
};

export default userReducer;
