/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import { AiOutlineLogout } from 'react-icons/ai';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { IoMdLogOut } from 'react-icons/io';
import homeIcon from '../../../assets/icons/home.svg';
// import storeIcon from '../../../assets/icons/stores.svg';
import CommonDialog from '../../dialog-box';
import LoginRegisterDialog from '../../login-register-dialog-box';
import { signOutStart } from '../../../redux/user/user.actions';
import botigaLogo from '../../../assets/images/Horizontal-Logo-Small.png';
import styles from './header.module.scss';

// eslint-disable-next-line no-shadow
const HeaderComponent = ({ signOutStart, userStatus }) => {
  const [open, setDialogOpen] = useState(false);
  const [isUserLogedIn, setIsUserLoggedIn] = useState(false);
  const [openLoginRedg, setDialogOpenLOginRedg] = useState(false);
  const handleOnLoginRedgClick = () => {
    setDialogOpenLOginRedg(true);
  };

  useEffect(() => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
      setDialogOpenLOginRedg(false);
    }
  }, [userStatus]);

  return (
    <>
      <div className={styles.headerblock}>
        <div className={styles.logo}>
          <NavLink to="/">
            <img src={botigaLogo} alt="logo" style={{ height: '50px' }} />
          </NavLink>
        </div>
        <div className={styles.headerMenus}>
          <ul>
            <li>
              <NavLink to="/" exact activeClassName={styles.active}>
                <img src={homeIcon} alt={homeIcon} />
                {' '}
                <span className="d-none d-sm-block">Home</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/" exact activeClassName="active" onClick={() => handleOnClick()}>
                <img src={storeIcon} alt={storeIcon} />
                {' '}
                <span className="d-none d-sm-block">Stores</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/" exact activeClassName="active" onClick={() => handleOnClick()}>
                <img src={storeIcon} alt={storeIcon} />
                {' '}
                <span className="d-none d-sm-block">Explore</span>
              </NavLink>
            </li> */}
          </ul>
        </div>
        {/* <div className={styles.logoutBox}>
          {userId && <AiOutlineLogout onClick={signOutStart} />}
        </div> */}
        <CommonDialog
          open={open}
          onDisagree={() => setDialogOpen(false)}
        />
        {/* {userId && <AiOutlineLogout onClick={signOutStart} />} */}
        {!isUserLogedIn && (
        <li
          className={styles.logInOutBox}
        >
          <p><HiOutlineUserCircle onClick={handleOnLoginRedgClick} style={{ height: '35px', width: '45px' }} /></p>
          <div onClick={handleOnLoginRedgClick} className={styles.logInOutBoxText}>Login</div>
        </li>
        )}
        {isUserLogedIn && (
          <li className={styles.logInOutBox}>
            <IoMdLogOut onClick={signOutStart} style={{ height: '35px', width: '45px' }} />
            <br />
            <div className={styles.logInOutBoxText} onClick={signOutStart}>Logout</div>
          </li>
        )}
      </div>
      <LoginRegisterDialog
        open={openLoginRedg}
        onDisagree={() => setDialogOpenLOginRedg(false)}
      />
    </>
  );
};

HeaderComponent.propTypes = {
  signOutStart: PropTypes.func,
  userStatus: PropTypes.string
};

const mapStateToProps = (state) => ({
  userId: state.user.currentUser,
  userStatus: state.user.userStatus
});

export default connect(mapStateToProps, { signOutStart })(HeaderComponent);
