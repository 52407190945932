import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { store } from '../../redux/store';
import { HOST } from '../consts/api';

const customAxios = axios.create({
  baseURL: HOST,
  timeout: 15000,
  headers: { 'Content-Type': 'application/json' }
});
const requestHandler = (request) => {
  request.headers.authorization = store.getState().user.token ? store.getState().user.token : 'liweShop';

  return request;
};

const errorHandler = (error) => {
  // localStorage.clear();
  // window.location = '/';
  // eslint-disable-next-line no-restricted-globals
  location.reload();
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => errorHandler(error)
);

export default customAxios;
