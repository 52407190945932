import React, { createContext } from 'react';
import { io } from 'socket.io-client';
import PropTypes from 'prop-types';

const SOCKET_SERVER = 'https://api.botiga.live'; // 'http://18.220.193.215:3000/';

const socket = io(SOCKET_SERVER, {
  autoConnect: true,
});
const SocketContext = createContext(socket);

const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.object
};
export { SocketContext, SocketProvider };
