import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AiOutlineShop, AiFillEye, AiFillHeart, } from 'react-icons/ai';
import { IoIosChatbubbles, IoIosShareAlt } from 'react-icons/io';
import { IoExpandOutline } from 'react-icons/io5';
import { BsFillCartFill, BsVolumeUp } from 'react-icons/bs';
// import { MdOutlineCampaign } from 'react-icons/md';
import { useParams, useNavigate } from 'react-router-dom';
import { Badge } from '@mui/material';
import { FiVolumeX } from 'react-icons/fi';
import ShareUrlDialog from '../../components/dialog-box/share-url-dialog';
import styles from '../home/home.module.scss';
import LoaderComponent from '../../components/loader-component';
import ProductListComponent from '../productlist';
import { VideoSection } from '../../components/styled/Sections.styled';
import Chat from '../../components/chat-component/chat';
import dummy from '../../assets/icons/dummy-image.jpg';
import { GET_WEBINAR_DETAILS, GET_CHAT_HISTORY } from '../../utils/consts/api';
import axios from '../../utils/config/http';
import AllProductComponent from '../productlist/all-product-list';
import CartSummaryPage from '../summary-cart';
import AuthComponent from '../../components/Auth';
import { Memoized } from '../../components/video-player';
import CurrentActiveProduct from '../productlist/current-active-poroduct';
import { SocketContext } from '../../components/socket/Socket';

// eslint-disable-next-line no-shadow
const WebinarMobileComponent = ({ userId, userName, userStatus, cartItems, storeName, productVideoId }) => {
  const [isMuted, setVolume] = useState(true);
  const [open, setDialogOpen] = useState(false);
  const [webinarDetails, setWebinarDetails] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [showVideoBasedproductDetail, setShowVideoBasedProdDetail] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [prodList, setCurrentProductList] = useState(null);
  const [currentVideoTime, getCurrentVideoTime] = useState(0);
  const [isShowCartOpen, setIsCartOpen] = useState(false);
  const [isUserLogedIn, setIsUserLoggedIn] = useState(false);
  const [elements, setElements] = useState([]);
  const [isAllProductsOpen, setShowProductDetail] = useState(false);
  const [chatDataList, setChatDataList] = useState([]);
  const socket = useContext(SocketContext);
  const likesCount = useRef(0);
  const totalViews = useRef(0);
  const redirectUrl = useRef('');
  const navigate = useNavigate();
  const isIosDevice = useRef(false);
  const [hearthCount, setHearthCount] = useState([]);
  const [keyCount, setKeyCount] = useState(0);

  const [showDiv, setDiv] = useState(false);
  // let i = 1;
  let tempId = 0;
  const { selectedWebinar } = useParams();
  useEffect(() => {
    if (selectedWebinar && selectedWebinar !== tempId) {
      setShowLoader(true);
      axios.get(GET_WEBINAR_DETAILS + selectedWebinar).then((response) => {
        if (response.data.responseCode === 0) {
          if (response.data.response.webinarStatus === 3 || response.data.response.webinarStatus === 4) {
            setCurrentVideo(`${'https://kqalmt4rdgmdih.data.mediastore.us-east-1.amazonaws.com/liweshop/'}${selectedWebinar}${'.m3u8'}`);
          }

          if (response.data.response.webinarStatus === 5) {
            setCurrentVideo(response.data.response.mediaPkgStreamUrl);
            setShowChat(true);
          }

          if (response.data.response.webinarStatus === 1 || response.data.response.webinarStatus === 2 || response.data.response.webinarStatus === 6) {
            setCurrentVideo(response.data.response.webinarTrailerVideo[0]);
          }
          setCurrentProductList(response.data.response.productDetails);
          likesCount.current = response.data.response.totalLikes;
          totalViews.current = response.data.response.totalViewers;
          redirectUrl.current = response.data.response.merchantStoreName;
          setWebinarDetails(response.data.response);
          setShowLoader(false);
          tempId = selectedWebinar;
          // i++;
        } else {
          console.log('Webinar detail not found');
        }
      });
    }
    // window.addEventListener('resize', checkIfMobile);
  }, [selectedWebinar]);

  useEffect(() => {
    if (productVideoId) {
      if (webinarDetails && webinarDetails.bookmarkProduct) {
        const d = webinarDetails.bookmarkProduct.find((item) => item.productId === productVideoId);
        if (d) {
          const video = document.getElementById('myVideo');
          if (video) {
            video.currentTime = d.streamingSeconds;
          }
        }
      }
    }
  }, [productVideoId]);

  useEffect(() => {
    const video = document.getElementById('myVideo');
    if (video) {
      video.play();
      video.muted = true;
      setVolume(true);
    }
  }, []);

  const getLikesViews = () => {
    axios.get(GET_WEBINAR_DETAILS + selectedWebinar).then((response) => {
      if (response.data.responseCode === 0) {
        likesCount.current = response.data.response.totalLikes;
        totalViews.current = response.data.response.totalViewers;
        // i++;
      } else {
        console.log('Webinar detail not found');
      }
    });
  };

  const onHeartClick = () => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
      const userInfo = {
        webinarId: selectedWebinar,
        customerId: userId,
        name: userName
      };
      setKeyCount(keyCount + 1);
      setHearthCount([...hearthCount, { key: keyCount }]);
      socket.emit('heartReaction', userInfo);
      setElements([...elements,
        <div id="hearts-alpaca" className={styles.hearts} key={elements.length}>
          <div className={styles.heart}> </div>
        </div>
      ]);
      getLikesViews();
    } else {
      setIsUserLoggedIn(false);
      setIsCartOpen(true);
      // setHeartAnime(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (socket.connected) {
        socket.on(`${selectedWebinar}_pubsub`, (resp) => {
          if (resp.eventName === 'numberOfLikes') {
            likesCount.current = resp.numberOfLikes + 50;
          }
          if (resp.eventName === 'heartReaction') {
            // keyCount.current += 1;
            // console.log('hearthCount', keyCount.current);
            // const tempElements = [...elements];
            // tempElements.push(<div id="hearts-alpaca" className={styles.hearts} key={tempElements.length}>
            //   <div className={styles.heart}> </div>
            // </div>);
            // setElements(tempElements);
            setElements((prev) => [...prev,
              <div id="hearts-alpaca" className={styles.hearts} key={prev.length}>
                <div className={styles.heart}> </div>
              </div>]);
            // setHearthCount((prev) => [...prev, { key: keyCount.current }]);
          }
          console.log(resp, 'resp');
          if (resp.eventName === 'totalViewers') {
            totalViews.current = resp.totalViewers + 50;
          }
          // totalViewers
        });
      }
    }, 5000);
  }, [selectedWebinar]);

  useEffect(() => {
    if (selectedWebinar) {
      axios.get(`${GET_CHAT_HISTORY}${selectedWebinar}/page/0/size/50`).then((response) => {
        setChatDataList(response.data.response.chatList);
        // if (chatList.length > 0) {
        //   const objDiv = document.getElementById('messageBox');
        //   objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
        // }
      }, (error) => {
        console.log(error);
      });
    }
  }, [selectedWebinar]);

  useEffect(() => {
    const interval = setInterval(() => {
      const video = document.getElementById('myVideo');
      if (video) {
        getCurrentVideoTime(video.currentTime);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    isIosDevice.current = ['iPhone'].indexOf(navigator.platform) >= 0;
  }, []);

  useEffect(() => {
    const vd = document.getElementById('myVideo');
    if (vd) {
      vd.disablepictureinpicture = true;
      getCurrentVideoTime(vd.currentTime);
      vd.addEventListener('error', () => {
        setCurrentVideo(webinarDetails.webinarTrailerVideo[0]);
      }, true);
    }
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
    }
  }, [userStatus]);

  const onClickTocloseCommonDiv = () => {
    setDiv(false);
  };

  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const openShareDialog = () => {
    setDialogOpen(true);
  };

  const changeSound = (event) => {
    event.stopPropagation();
    setVolume(!isMuted);
    const video = document.getElementById('myVideo');
    video.muted = !video.muted;
  };

  const handleShowChart = () => {
    const d = !showChat;
    setShowChat(d);
  };

  const getCartOpen = () => {
    setIsCartOpen(true);
  };
  const showAllProducts = () => {
    setDiv(false);
    setShowVideoBasedProdDetail(false);
    setShowProductDetail(true);
  };

  const hideProductList = () => {
    setIsCartOpen(false);
    setDiv(false);
    setShowProductDetail(false);
  };
  const backToProductDetailsDiv = () => {
    // setIsUserLoggedIn(false);
    setIsCartOpen(false);
  };
  // const getHideVideoProdDetail = () => {
  //   setDiv(false);
  //   setShowVideoBasedProdDetail(false);
  // };

  const openCurrentProductDetail = () => {
    const video = document.getElementById('myVideo');
    getCurrentVideoTime(video.currentTime);
    setDiv(true);
    setShowProductDetail(false);
    setShowVideoBasedProdDetail(true);
  };

  const onClickExpand = () => {
    return navigate(`/store/${redirectUrl.current}`);
  };
  const hideAllOpenSections = () => {
    setDiv(false);
    setShowProductDetail(false);
    setShowVideoBasedProdDetail(false);
    setIsCartOpen(false);
  };

  return (
    <>
      {showLoader && <LoaderComponent />}
      {!showLoader && (
        <>
          {webinarDetails && (
            <div className={styles.homeblock}>
              <VideoSection isExpanded className={styles.bgWrapper}>
                {/* <video id="vdtag" playsInline muted autoPlay src={webinarDetails && webinarDetails.webinarTrailerVideo[0] ? webinarDetails.webinarTrailerVideo[0] : vdoUrl} disablepictureinpicture controlsList="nofullscreen nodownload noremoteplayback noplaybackrate nopi" width="100%" height="100%" oncanplay="this.play()">
                Your browser does not support the video tag.
              </video> */}
                <Memoized currentVideo={currentVideo} webinarStatus={webinarDetails.webinarStatus} />
                {/* <Memoized currentVideo={currentVideo} webinarStatus={webinarDetails.webinarStatus} /> */}
                {webinarDetails && (
                  <div className={styles.profileMainBlock}>
                    <div className={styles.dataBlock}>
                      <div className={styles.storeDetail}>
                        <img className={`img-fluid ${styles.profileImage}`} src={webinarDetails.webinarImageList[0] || dummy} onError={handleImageErr} alt="No Inage" />
                        <div className={styles.profileBlock}>
                          <h6>{webinarDetails.webinarTitle}</h6>
                          <p>{storeName}</p>
                        </div>
                      </div>

                      <div className={`mt-2 ${styles.likesAndLiveuser}`}>
                        <div className={styles.LiveUsers}>
                          <AiFillEye />
                          &nbsp;
                          <span style={{ marginTop: '-7px' }}>{totalViews.current ? totalViews.current : 50}</span>
                        </div>
                        &nbsp;
                        <div className={styles.totalLikes}>
                          <AiFillHeart />
                          &nbsp;
                          <span style={{ marginTop: '-7px' }}>{likesCount.current ? likesCount.current : 50}</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.campaignlayout}>
                      <IoExpandOutline onClick={onClickExpand} />
                    </div>
                  </div>
                )}
                <>
                  {selectedWebinar && showChat && webinarDetails.chattingEnabled === 1
                    && (
                    <div className={styles.chatBlock}>
                      <Chat webinarId={selectedWebinar} webInarStatus={webinarDetails.webinarStatus} chartLists={chatDataList} handleShowChart={handleShowChart} />
                    </div>

                    )}

                  <div className="airfillheartContainer">
                    {/* {hearthCount.map((key) => {
                      return (
                        <div id="hearts-alpaca" className={styles.hearts} key={key.key}>
                          <div className={styles.heart}> </div>
                        </div>
                      );
                    })} */}
                    {
                      elements.map((element) => {
                        return (
                          element
                        );
                      })
                    }
                  </div>
                  {currentVideo && (
                    <div className={styles.videoControls}>
                      <ul>
                        <li>
                          {!isMuted && <BsVolumeUp onClick={changeSound} />}
                          {isMuted && <FiVolumeX onClick={changeSound} />}
                        </li>
                        <li><AiOutlineShop onClick={showAllProducts} /></li>
                        <li>
                          <Badge badgeContent={cartItems.length} color="error" onClick={getCartOpen}>
                            <BsFillCartFill onClick={getCartOpen} />
                          </Badge>
                        </li>
                        <li><IoIosChatbubbles onClick={handleShowChart} /></li>
                        <li>
                          <IoIosShareAlt onClick={openShareDialog} />
                        </li>
                        <li>
                          <AiFillHeart onClick={onHeartClick} />
                        </li>
                      </ul>
                    </div>
                  )}
                  {prodList.length > 0 && (
                    <>
                      {showDiv && (
                        <div className={`${styles.proDuctListBlock} ${styles.prodDetailsb} ${isIosDevice.current && styles.proDuctListBlockIos}`} style={{ bottom: '0vh' }}>
                          {/* <div className={styles.showHideMark} onClick={onClickTocloseCommonDiv}>
                        <h1>&nbsp;</h1>
                      </div> */}
                          <div className={styles.showHideMark} onClick={onClickTocloseCommonDiv}>
                            <h1>&nbsp;</h1>
                          </div>
                          <div className={styles.commonFloatingBlock}>
                            <ProductListComponent currentVideoTime={currentVideoTime} webinarDetail={webinarDetails} />
                          </div>
                        </div>
                      )}
                      {!showDiv && (
                        <div className={`${styles.proDuctListBlock} ${isIosDevice.current && styles.proDuctListBlockIos1}`}>
                          <div className={styles.webinarProduct}>
                            <div className={styles.listMainBlock} style={{ cursor: 'pointer' }} onClick={openCurrentProductDetail}>
                              <CurrentActiveProduct webinarDetail={webinarDetails} currentVideoTime={currentVideoTime} />
                            </div>
                            {/* {!showDiv && <div className={styles.customPlusProduct}><AiOutlinePlus /></div>} */}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {isAllProductsOpen && !showVideoBasedproductDetail && (
                    <div className={`${styles.proDuctListBlock} ${isIosDevice.current && styles.proDuctListBlockIos}`}>
                      <div className={styles.showHideMark} onClick={onClickTocloseCommonDiv}>
                        <h1>&nbsp;</h1>
                      </div>
                      <div className={styles.commonFloatingBlock}>
                        <AllProductComponent webinarDetails={webinarDetails} hidecartBlock={hideAllOpenSections} />
                      </div>
                    </div>
                  )}
                  {isShowCartOpen && isUserLogedIn && (
                    <div className={`${styles.proDuctListBlock} ${isIosDevice.current && styles.proDuctListBlockIos}`}>
                      <div className={styles.showHideMark} onClick={hideAllOpenSections}>
                        <h1>&nbsp;</h1>
                      </div>
                      <div className={styles.commonFloatingBlock}>
                        <CartSummaryPage hidecartBlock={hideProductList} />
                      </div>
                    </div>
                  )}
                  {isShowCartOpen && !isUserLogedIn && (
                    <div className={`${styles.proDuctListBlock} ${isIosDevice.current && styles.proDuctListBlockIos}`}>
                      <div className={styles.commonFloatingBlock}>
                        <AuthComponent showProductDetails={backToProductDetailsDiv} />
                      </div>
                    </div>
                  )}
                  {(isShowCartOpen || isAllProductsOpen || showDiv) && (
                    <div className={styles.dummyClikableBox} onClick={hideAllOpenSections}>
                      &nbsp;
                    </div>
                  )}
                </>
              </VideoSection>
            </div>
          )}
        </>
      )}
      <ShareUrlDialog
        open={open}
        onDisagree={() => setDialogOpen(false)}
        url={selectedWebinar}
      />
    </>
  );
};

WebinarMobileComponent.propTypes = {
  userStatus: PropTypes.number,
  storeName: PropTypes.string,
  cartItems: PropTypes.array,
  // merchantImage: PropTypes.string,
  productVideoId: PropTypes.string,
  userId: PropTypes.string,
  userName: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  storeName: state.list.merchantStoreName,
  cartItems: state.cart.cartItems,
  merchantImage: state.list.merchantImage,
  productVideoId: state.list.productVideoId,
  userId: state.user.currentUser,
  userName: state.user.userName,
});

export default connect(mapStateToProps, null)(WebinarMobileComponent);
