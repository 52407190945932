import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import './stripe.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { updateStripeCustomerID } from '../../redux/user/user.actions';
import { API_CREATE_STRIPE_CUSTOMER, STRIPE_SECRET_KEY } from '../../utils/consts/api';
import './CheckoutForm.css';
// import { API_STRIPE_PAYMENT } from '../../utils/consts/api';
// import api from './api';

// eslint-disable-next-line no-shadow
const PaymentMethodComponent = ({ getCardInfo, tokenh, stripeCustomerId, updateStripeCustomerID }) => {
  // const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  // const [metadata, setMetadata] = useState(null);
  // const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isChecked, setCheckboxCheck] = useState(false);
  const [stpCustomId, setStpCustomId] = useState(stripeCustomerId);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    const headers = {
      Authorization: `Bearer ${STRIPE_SECRET_KEY}`
    };

    setProcessing(true);
    try {
      if (isChecked) {
        if (stpCustomId) {
          const { token, err } = await stripe.createToken(elements.getElement(CardElement));
          if (err) {
            setError(err);
            setProcessing(false);
          } else if (token && token.id) {
            try {
              token.card.name = ev.target.name.value;
              const postData = {
                source: token.id
              };
              const formBody = Object.keys(postData).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(postData[key])}`).join('&');
              // const d = JSON.parse(postData);
              await axios.post(`https://api.stripe.com/v1/customers/${stpCustomId}/sources`, formBody, { headers })
                .then((res) => {
                  if (res) {
                    // toast.success('Card details added successfully!', {
                    //   position: 'top-right',
                    //   autoClose: 4000,
                    //   hideProgressBar: false,
                    //   closeOnClick: true,
                    //   pauseOnHover: true,
                    //   draggable: true,
                    //   progress: undefined,
                    // });
                    getCardInfo(res.data, true);
                  }
                }, (errp) => {
                  setProcessing(false);
                  toast.error(errp, {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
            } catch (errl) {
              setProcessing(false);
              console.log(`Payment failed${errl}`);
            }
          }
        } else {
          const headers1 = {
            'Content-Type': 'application/json',
            authorization: tokenh
          };
          const res = await axios.post(API_CREATE_STRIPE_CUSTOMER, {}, { headers: headers1 });
          if (res.data.responseCode === 0) {
            // toast.success('Stripe customer created successfylly!', {
            //   position: 'top-right',
            //   autoClose: 4000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            const stripcustomerId = res.data.response.stripeCustomerId;
            updateStripeCustomerID(stripcustomerId);
            setStpCustomId(stpCustomId);
            const { token, err } = await stripe.createToken(elements.getElement(CardElement));
            if (err) {
              setError(err);
              setProcessing(false);
            } else if (token && token.id) {
              try {
                // const headers1 = {
                //   Authorization: `Bearer ${STRIPE_SECRET_KEY}`
                // };
                token.card.name = ev.target.name.value;
                const postData = {
                  source: token.id
                };
                const formBody = Object.keys(postData).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(postData[key])}`).join('&');
                // const d = JSON.parse(postData);
                await axios.post(`https://api.stripe.com/v1/customers/${stripcustomerId}/sources`, formBody, { headers })
                  .then((resp) => {
                    if (resp) {
                      setProcessing(false);
                      getCardInfo(resp.data, true);
                    }
                  }, (errp) => {
                    setProcessing(false);
                    toast.error(errp, {
                      position: 'top-right',
                      autoClose: 4000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  });
              } catch (errl) {
                setProcessing(false);
                console.log(`Payment failed${errl}`);
              }
            }
          } else {
            toast.error(res.errorMsg, {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setProcessing(false);
          }
        }
      } else {
        const { token, err } = await stripe.createToken(elements.getElement(CardElement));
        if (err) {
          setError(err);
          setProcessing(false);
        } else if (token && token.id) {
        // setSucceeded(true);
          token.cardName = ev.target.name.value;
          getCardInfo(token, false);
        }
      }
    } catch (err) {
      setProcessing(false);
      console.log(`Payment failed${err}`);
    }
  };
  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    };

    const handleCheckChange = (e) => {
      if (e.target.checked) {
        setCheckboxCheck(true);
      } else {
        setCheckboxCheck(false);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row mb-3">
            {/* <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              autoComplete="cardholder"
              className="sr-input"
            /> */}
            <TextField name="name" fullWidth id="name-basic" label="Name on Card" variant="outlined" />
          </div>

          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
            />
          </div>
          <div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Remember card detail"
                checked={isChecked}
                onChange={handleCheckChange}
              />
            </FormGroup>
          </div>
        </div>

        {error && <div className="message sr-field-error">{error}</div>}

        <button
          className="btn SubmitButton"
          disabled={processing || !stripe}
          type="submit"
        >
          {processing ? 'Processing…' : 'Add Card'}
        </button>
      </form>
    );
  };

  return (
    <div className="checkout-form">
      <div className="sr-payment-form">
        <div className="sr-form-row" />
        {renderForm()}
      </div>
    </div>
  );
};

PaymentMethodComponent.propTypes = {
  tokenh: PropTypes.string,
  // address: PropTypes.object,
  // userId: PropTypes.string
  getCardInfo: PropTypes.func.isRequired,
  stripeCustomerId: PropTypes.string,
  // userEmail: PropTypes.string
  updateStripeCustomerID: PropTypes.func
};

const mapStateToProps = (state) => ({
  tokenh: state.user.token,
  userId: state.user.currentUser,
  stripeCustomerId: state.user.stripeCustomerId
  // userEmail: state.user.userEmail
});

export default connect(mapStateToProps, { updateStripeCustomerID })(PaymentMethodComponent);
