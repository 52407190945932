/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';
// import styles from './productList.module.scss';
// import dummy from '../../assets/icons/dummy-image.jpg';
import axios from '../../utils/config/http';
import { GET_PRODUCT_DETAILS } from '../../utils/consts/api';
import CartSummaryPage from '../summary-cart';
import { addCartItem, addCartItemFailure } from '../../redux/cart/cart.actions';
import IndividualProductDetail from './product-details';
import AuthComponent from '../../components/Auth';
import LoaderDivComponent from '../../components/loader-component/div-loader';

// eslint-disable-next-line no-shadow
const ProductListComponent = ({ userStatus, addCartItem, webinarDetail, currentVideoTime, hideVideoProdDetail, isCartAdded, addCartItemFailure, showCaseProdId }) => {
  // const prodList = useSelector(
  //   (state) => state.list.productList
  // );
  // const handleImageErr = (e) => {
  //   e.target.src = dummy;
  // };
  // console.log(currentVideoTime);
  // const [showDiv, setDiv] = useState(false);
  const [prodDetails, setprodDetails] = useState({});
  const [defaultValueOfparam, setdefaultValueOfparam] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [isBuNowActive, setIsBuyNow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  // const [prodList, setProdList] = useState([]);
  const onClickToShowDetails = async (id) => {
    if (id !== null) {
      try {
        const res = await axios.get(GET_PRODUCT_DETAILS + id);
        if (res.data.responseCode === 0) {
          res.data.response.commonCategoryFields.forEach((a) => {
            res.data.response.commonAttributes.forEach((b) => {
              if (a.categoryFieldId === b.categoryFieldId) {
                b.type = a.type;
                b.hint = a.hint;
              }
            });
          });
          // console.log('product detail found', res.data.response);
          setprodDetails(res.data.response);
          setdefaultValueOfparam(res.data.response.parameterList[0]);
          setShowLoader(false);
          // setDiv(true);
        } else {
          console.log('product detail not found');
        }
      } catch (err) {
        console.log('product detail not found');
      }
    }
  };

  const [showCart, setshowCart] = useState(false);
  const [openToLogin, setOpenToLogin] = useState(false);
  const onClickAddToCart = (selectedCategoryData) => {
    setShowLoader(true);
    setIsBuyNow(false);
    if (userStatus === 1) {
      setOpenToLogin(true);
      const obj = {
        merchantId: prodDetails.merchantId,
        productId: prodDetails.id,
        categoryList: selectedCategoryData,
        webinarId: webinarDetail.webinarStatus === 5 ? webinarDetail.webinarId : ''
      };
      addCartItem(obj);
    } else {
      setOpenToLogin(false);
      // setshowCart(false);
      setIsActive(true);
      setShowLoader(false);
    }
  };

  const buySelectedProductAction = (selectedCategoryData) => {
    setShowLoader(true);
    setIsBuyNow(true);
    if (userStatus === 1) {
      setOpenToLogin(true);
      const obj = {
        merchantId: prodDetails.merchantId,
        productId: prodDetails.id,
        categoryList: selectedCategoryData,
        webinarId: webinarDetail.webinarStatus === 5 ? webinarDetail.webinarId : ''
      };
      addCartItem(obj);
    } else {
      setOpenToLogin(false);
      // setshowCart(true);
      setIsActive(true);
      setShowLoader(false);
    }
  };

  let k = 0;
  useEffect(() => {
    if (k === 0) {
      if (userStatus === 1) {
        setOpenToLogin(true);
        // setshowCart(true);
        setIsActive(false);
      }
      k++;
    }
  }, [userStatus]);

  useEffect(() => {
    if (isCartAdded) {
      setshowCart(true);
      setShowLoader(false);
      addCartItemFailure();
    }
  }, [isCartAdded]);

  useEffect(() => {
    if (webinarDetail.bookmarkProduct && webinarDetail.bookmarkProduct.length > 0) {
      const dta = webinarDetail.bookmarkProduct;
      if (dta.length > 1 && webinarDetail.webinarStatus !== 5) {
        for (let i = 0; i < dta.length; i++) {
          if (dta[i] && currentVideoTime > dta[i].streamingSeconds && i === dta.length - 1) {
            onClickToShowDetails(dta[i].productId);
            break;
          } else if (dta[i] && dta[i].streamingSeconds <= currentVideoTime && currentVideoTime < dta[i + 1].streamingSeconds) {
            onClickToShowDetails(dta[i].productId);
            break;
          } else if (currentVideoTime <= dta[0].streamingSeconds) {
            onClickToShowDetails(dta[0].productId);
            break;
          }
        }
      } else if (showCaseProdId && webinarDetail.webinarStatus === 5) {
        onClickToShowDetails(showCaseProdId);
      } else {
        const l = dta.length - 1;
        onClickToShowDetails(dta[l].productId);
      }

      // if (webinarDetail.bookmarkProduct.length === 1) {
      //   onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      // } else if (webinarDetail.bookmarkProduct.length === 2) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // } else if (webinarDetail.bookmarkProduct.length === 3) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[1].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[2].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[2].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // } else if (webinarDetail.bookmarkProduct.length === 4) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[2].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[3].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[3].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[1].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[2].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[2].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // } else if (webinarDetail.bookmarkProduct.length === 5) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[3].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[4].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[4].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[2].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[3].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[3].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[1].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[2].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[2].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // } else if (webinarDetail.bookmarkProduct.length === 6) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[4].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[5].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[5].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[3].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[4].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[4].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[2].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[3].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[3].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[1].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[2].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[2].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // }
    } else if (webinarDetail.webinarStatus === 5 && showCaseProdId) {
      onClickToShowDetails(showCaseProdId);
    } else if (webinarDetail.webinarStatus !== 5 && webinarDetail.productDetails && webinarDetail.productDetails.length > 0) {
      onClickToShowDetails(webinarDetail.productDetails[0].id);
    } else {
      onClickToShowDetails(webinarDetail.productDetails[0].id);
    }
  }, [currentVideoTime, webinarDetail, showCaseProdId]);

  const backToProductDetailsDiv = () => {
    setOpenToLogin(false);
    setshowCart(false);
  };

  // const onClickback = () => {
  //   setprodDetails('');
  //   // setDiv(false);
  //   setOpenToLogin(false);
  //   setshowCart(false);
  //   // setopenToRedg(false);
  // };

  return (
    <>
      {/* {!showDiv && (
        <div className="row m-0">
          <div className="col-12">
            <h6 className={styles.prodListHeading}>Store Name</h6>
          </div>
          <div className="col-12" style={{ height: '350px', overflowY: 'auto' }}>
            <div className="row m-0">
              {prodList.map((element) => (
                <div className="col-4" onClick={() => onClickToShowDetails(element)}>
                  <div className={styles.listMainBlock} style={{ cursor: 'pointer' }}>
                    <span className={`badge ${styles.custombadge}`}>{element ? `${'$'} ${element.parameterList[0].price}` : 'no price'}</span>
                    <img className={`img-fluid ${styles.prodImage}`} src={element ? element.productImageList[0] : dummy} onError={handleImageErr} alt="No Inage" />
                    <h6 className={styles.prodName}>{element ? element.name : 'no name'}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )} */}
      {!showCart && !showLoader && !isActive && (
        <div className="row m-0">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '8px 1px' }}>
            <span> &nbsp; </span>
            <h6 style={{ marginBottom: '0px', marginLeft: '-210px', fontSize: '16px', fontWeight: 'bolder' }}>Product Details</h6>
            <AiOutlineCloseCircle style={{ cursor: 'pointer' }} onClick={hideVideoProdDetail} />
          </div>
          {!showLoader && <IndividualProductDetail prodDetails={prodDetails} defaultValueOfparam={defaultValueOfparam} callAddtoCart={onClickAddToCart} buySelectedProduct={buySelectedProductAction} hideVideoProdDetail={hideVideoProdDetail} />}
        </div>
      )}
      {/* {showCart && !openToLogin && <AiOutlineArrowLeft style={{ position: 'absolute', left: '10px', top: '10px', zIndex: '99999', cursor: 'pointer' }} onClick={backToProductDetailsDiv} />} */}
      {showCart && !showLoader && openToLogin && <CartSummaryPage hidecartBlock={backToProductDetailsDiv} isBuNowActive={isBuNowActive} hideVideoProdDetail={hideVideoProdDetail} />}
      {!showCart && isActive && !showLoader && !openToLogin && <AuthComponent showProductDetails={backToProductDetailsDiv} />}
      {showLoader && (
        <div style={{ position: 'relative' }}>
          <LoaderDivComponent />
        </div>
      )}
    </>
  );
};

ProductListComponent.propTypes = {
  // token: PropTypes.string,
  userStatus: PropTypes.number,
  addCartItem: PropTypes.func.isRequired,
  webinarDetail: PropTypes.object,
  currentVideoTime: PropTypes.number,
  hideVideoProdDetail: PropTypes.func.isRequired,
  isCartAdded: PropTypes.bool,
  addCartItemFailure: PropTypes.func,
  showCaseProdId: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  isCartAdded: state.cart.isCartAdded
});

export default connect(mapStateToProps, { addCartItem, addCartItemFailure })(ProductListComponent);
