const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
  GOOGLE_SIGN_IN_SUCCESS: 'GOOGLE_SIGN_IN_SUCCESS',
  FACEBOOK_SIGN_IN_START: 'FACEBOOK_SIGN_IN_START',
  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  EMAIL_SIGN_UP_START: 'EMAIL_SIGN_UP_START',
  EMAIL_SIGN_UP_SUCCESS: 'EMAIL_SIGN_UP_SUCCESS',
  EMAIL_SIGN_UP_FAILURE: 'EMAIL_SIGN_UP_FAILURE',
  CLEAR_USER_NAME_MESSAGES: 'CLEAR_USER_NAME_MESSAGES',
  CLEAR_CURRENT_STATE: 'CLEAR_CURRENT_STATE',
  CREATE_USER_NAME: 'CREATE_USER_NAME',
  CREATE_USER_NAME_SUCCESS: 'CREATE_USER_NAME_SUCCESS',
  CREATE_USER_NAME_FAILURE: 'CREATE_USER_NAME_FAILURE',
  CREATE_USER_NAME_SERVER_ERROR: 'CREATE_USER_NAME_SERVER_ERROR',
  VALIDATE_OTP_START: 'VALIDATE_OTP_START',
  VALIDATE_OTP_SUCCESS: 'VALIDATE_OTP_SUCCESS',
  VALIDATE_OTP_FAILURE: 'VALIDATE_OTP_FAILURE',
  GET_CUSTOMER_DETAILS: 'GET_CUSTOMER_DETAILS',
  GET_CUSTOMER_DETAILS_SUCCESS: 'GET_CUSTOMER_DETAILS_SUCCESS',
  GET_CUSTOMER_ADDRESS: 'GET_CUSTOMER_ADDRESS',
  GET_CUSTOMER_ADDRESS_SUCCESS: 'GET_CUSTOMER_ADDRESS_SUCCESS',
  GET_CUSTOMER_ADDRESS_FAILURE: 'GET_CUSTOMER_ADDRESS_FAILURE',
  UPDATE_CUSTOMER_ADDRESS_LOCAL: 'UPDATE_CUSTOMER_ADDRESS_LOCAL',
  UPDATE_STRIPE_CUSTOMERID: 'UPDATE_STRIPE_CUSTOMERID',
  UPDATE_ERROR_MESSAGE: 'UPDATE_ERROR_MESSAGE'
};

export default UserActionTypes;
