import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHlsPlayer from 'react-hls-player';

export const VideoPlayer = ({ currentVideo, webinarStatus }) => {
  const [isHLS, setHLSVideo] = useState(true);

  useEffect(() => {
    if (currentVideo.includes('m3u8')) {
      setHLSVideo(true);
    } else {
      setHLSVideo(false);
    }
  }, []);

  return (
    <>
      {isHLS && webinarStatus === 3 && (
        <ReactHlsPlayer
          className="custom-video-controls"
          src={currentVideo}
          autoPlay
          controls
          id="myVideo"
          width="100%"
          height="100%"
          playsInline
          muted
          loop
        />
      )}
      {isHLS && webinarStatus !== 3 && (
        <ReactHlsPlayer
          className="custom-video-controls"
          src={currentVideo}
          autoPlay
          id="myVideo"
          width="100%"
          height="100%"
          playsInline
          muted
          loop
        />
      )}
      {!isHLS && (
        <video className="custom-video-controls" playsInline width="100%" height="100%" autoPlay id="myVideo" src={currentVideo} loop muted>
          Your browser does not support HTML video.
        </video>
      )}
    </>
  );
};
export const Memoized = React.memo(VideoPlayer);
export default VideoPlayer;
VideoPlayer.propTypes = {
  currentVideo: PropTypes.string,
  webinarStatus: PropTypes.number
};
