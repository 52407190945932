/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { IoMdLogOut } from 'react-icons/io';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginRegisterDialog from '../../login-register-dialog-box';
import { signOutStart } from '../../../redux/user/user.actions';
import styles from './mobile-layout.module.scss';
import homeIcon from '../../../assets/icons/home.svg';
// import storeIcon from '../../../assets/icons/stores.svg';

const FooterMobileComponent = ({ userStatus, signOutStart }) => {
  const [openLoginRedg, setDialogOpenLOginRedg] = useState(false);
  const [isUserLogedIn, setIsUserLoggedIn] = useState(false);
  useEffect(() => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
      setDialogOpenLOginRedg(false);
    }
  }, [userStatus]);
  const handleOnLoginRedgClick = () => {
    setDialogOpenLOginRedg(true);
  };
  return (
    <>
      <div className={styles.mobileFooter}>
        <ul>
          <li>
            <NavLink to="/mobile" exact activeClassName={styles.active}>
              <p><img src={homeIcon} alt={homeIcon} /></p>
              <p>Home</p>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/mobile" exact activeClassName="active">
              <p><img src={storeIcon} alt={storeIcon} /></p>
              <p>Store</p>
            </NavLink>
          </li> */}
          {!isUserLogedIn && (
            <li>
              <p><HiOutlineUserCircle onClick={handleOnLoginRedgClick} /></p>
              <p>Login</p>
            </li>
          )}
          {isUserLogedIn && (
          <li>
            <IoMdLogOut onClick={signOutStart} />
            <br />
            Logout
          </li>
          )}
        </ul>
      </div>
      <LoginRegisterDialog
        open={openLoginRedg}
        onDisagree={() => setDialogOpenLOginRedg(false)}
      />
    </>
  );
};

FooterMobileComponent.propTypes = {
  userStatus: PropTypes.string,
  signOutStart: PropTypes.func
};

const mapStateToProps = (state) => ({
  userStatus: state.user.userStatus
});

export default connect(mapStateToProps, { signOutStart })(FooterMobileComponent);
