import React from 'react';
import MainLayoutComponent from '../components/layout/main-layout';
import MobileLayoutComponent from '../components/layout/mobile-layout';
import HomeComponent from '../views/home';
import StoreComponent from '../views/store';
import MobileHomeComponent from '../mobile/home';
import MobileStoreComponent from '../mobile/store';
import WebinarComponent from '../views/webinar';
import TandcComponent from '../views/terms/tandc';
import PrivacypolicyComponent from '../views/terms/privacypolicy';

const routes = [
  {
    path: '',
    element: <MainLayoutComponent />,
    children: [
      { path: '/', element: <HomeComponent /> },
      { path: '/store/:storeId', element: <StoreComponent /> },
      { path: '/show/:selectedWebinar', element: <WebinarComponent /> },
      { path: '/tandc', element: <TandcComponent /> },
      { path: '/privacy-policy', element: <PrivacypolicyComponent /> }
    ]
  },
  {
    path: 'mobile',
    element: <MobileLayoutComponent />,
    children: [
      { path: '/', element: <MobileHomeComponent /> },
      { path: '/store/:storeId', element: <MobileStoreComponent /> },
      { path: '/tandc', element: <TandcComponent /> },
      { path: '/privacy-policy', element: <PrivacypolicyComponent /> }
    ]
  }
];

export default routes;
