/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AiOutlineClose } from 'react-icons/ai';
import { RiFileCopy2Line } from 'react-icons/ri';
// import { TinyURL } from 'tinyurl';
import styles from './dialogBox.module.scss';

const ShareUrlDialog = ({ open, onDisagree, url }) => {
  const [isCopied, setTextCopied] = useState(false);

  const copyUrltoClipboard = () => {
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(`https://shop.botiga.live/show/${url}`);
      }
      setTextCopied(true);
      setTimeout(() => { setTextCopied(false); }, 4000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={onDisagree}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onBackdropClick="false"
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: '0px 5px 0', textAlign: 'right' }}>
          <AiOutlineClose style={{ cursor: 'pointer' }} onClick={onDisagree} />
        </DialogTitle>
        <DialogContent style={{ maxWidth: '350px', width: '300px' }}>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <div className={styles.copyBlock} onClick={copyUrltoClipboard}>
                <div>https://shop.botiga.live</div>
                <div><RiFileCopy2Line onClick={copyUrltoClipboard} /></div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {isCopied && (
        <div style={{ width: '100%', textAlign: 'center', position: 'absolute', bottom: '50px' }}>
          <div className={styles.successCopy}>URL Copied!</div>
        </div>
      )}
    </div>
  );
};

export default ShareUrlDialog;
