/* eslint-disable react/prop-types */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AiOutlineClose } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import applelogo from '../../assets/icons/apple-logo.png';
import googleplaylogo from '../../assets/icons/Google-play.png';
import styles from './dialogBox.module.scss';

const CommonDialog = ({ open, onDisagree }) => {
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={onDisagree}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onBackdropClick="false"
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: '0px 5px 0', textAlign: 'right' }}>
          <AiOutlineClose style={{ cursor: 'pointer' }} onClick={onDisagree} />
        </DialogTitle>
        <DialogContent style={{ width: '255px' }}>
          <DialogContentText id="alert-dialog-description">
            <div style={{ width: '100%', textAlign: 'center' }}>
              <FiDownload style={{ color: '#F42351', fontSize: '65px' }} />
              <h6 className={styles.headText}>Download our the app</h6>
              <h6 style={{ fontSize: '13px', color: '#727272' }}>Please download the app to</h6>
              <h6 style={{ fontSize: '13px', color: '#727272', marginBottom: '25px' }}>enjoy the full experience</h6>
              <img style={{ height: '50px', width: '70%', marginBottom: '15px', cursor: 'pointer' }} src={applelogo} alt="" />
              <img style={{ height: '50px', width: '70%', cursor: 'pointer' }} src={googleplaylogo} alt="" />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CommonDialog;
