import CartActionTypes from './cart.types';

export const toggleCartHidden = () => ({
  type: CartActionTypes.TOGGLE_CART_HIDDEN
});

export const addCartItem = (item) => ({
  type: CartActionTypes.ADD_CART_ITEM,
  payload: item
});

export const removeCartItem = (item) => ({
  type: CartActionTypes.REMOVE_CART_ITEM,
  payload: item
});

export const clearItemFromCart = (item) => ({
  type: CartActionTypes.CLEAR_ITEM_FROM_CART,
  payload: item
});

export const clearCart = () => ({
  type: CartActionTypes.CLEAR_CART
});

export const deleteCartItem = (item) => ({
  type: CartActionTypes.DELETE_CART_ITEM,
  payload: item
});

export const getCartItems = () => ({
  type: CartActionTypes.GET_CART_ITEMS
});

export const getCartItemSuccess = (item) => ({
  type: CartActionTypes.GET_CART_ITEM_SUCCESS,
  payload: item
});

export const getCartItemfailure = (item) => ({
  type: CartActionTypes.GET_CART_ITEM_FAILURE,
  payload: item
});

export const addCartItemSuccess = (item) => ({
  type: CartActionTypes.ADD_ITEM_CART_SUCCESS,
  payload: item
});

export const addCartItemFailure = (item) => ({
  type: CartActionTypes.ADD_ITEM_CART_FAILURE,
  payload: item
});

export const removeCartItemFailure = (item) => ({
  type: CartActionTypes.REMOVE_ITEM_CART_FAILURE,
  payload: item
});

export const removeCartItemSuccess = (item) => ({
  type: CartActionTypes.REMOVE_ITEM_CART_SUCCESS,
  payload: item
});

export const increaseCartItemQuantity = (item) => ({
  type: CartActionTypes.INCREASE_CART_QUANTITY,
  payload: item
});

export const reduceCartItemQuantity = (item) => ({
  type: CartActionTypes.REDUCE_CART_QUANTITY,
  payload: item
});

export const changeCartQauantitySuccess = (item) => ({
  type: CartActionTypes.CHANGE_ITEM_CART_SUCCESS,
  payload: item
});

export const changeCartQauantityFailure = (item) => ({
  type: CartActionTypes.CHANGE_ITEM_CART_FAILURE,
  payload: item
});
