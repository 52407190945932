import UserActionTypes from './user.types';

export const googleSignInStart = (googlePayLoad) => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START,
  payload: googlePayLoad
});

export const googleSignInSuccess = (user) => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_SUCCESS,
  payload: user
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error
});

export const emailSignupStart = (user) => ({
  type: UserActionTypes.EMAIL_SIGN_UP_START,
  payload: user
});
export const emailSignupSuccess = (user) => ({
  type: UserActionTypes.EMAIL_SIGN_UP_SUCCESS,
  payload: user
});

export const emailSignupFailure = (error) => ({
  type: UserActionTypes.EMAIL_SIGN_UP_FAILURE,
  payload: error
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error
});

export const clearState = (error) => ({
  type: UserActionTypes.CLEAR_CURRENT_STATE,
  payload: error
});

export const clearUsernameState = () => ({
  type: UserActionTypes.CLEAR_USER_NAME_MESSAGES
});

export const createUserName = (name) => ({
  type: UserActionTypes.CREATE_USER_NAME,
  payload: name
});

export const createUserNameSuccess = (user) => ({
  type: UserActionTypes.CREATE_USER_NAME_SUCCESS,
  payload: user
});

export const createUserNameFailure = (fail) => ({
  type: UserActionTypes.CREATE_USER_NAME_FAILURE,
  payload: fail
});

export const createUserNameError = (error) => ({
  type: UserActionTypes.CREATE_USER_NAME_SERVER_ERROR,
  payload: error
});

export const validateOtp = (name) => ({
  type: UserActionTypes.VALIDATE_OTP_START,
  payload: name
});

export const validateOtpSuccess = (user) => ({
  type: UserActionTypes.VALIDATE_OTP_SUCCESS,
  payload: user
});

export const validateOtpFailure = (fail) => ({
  type: UserActionTypes.VALIDATE_OTP_FAILURE,
  payload: fail
});

export const getCustomerDetails = () => ({
  type: UserActionTypes.GET_CUSTOMER_DETAILS
});

export const getCustomerDetailsSuccess = (userData) => ({
  type: UserActionTypes.GET_CUSTOMER_DETAILS_SUCCESS,
  payload: userData
});

export const getCustomerAddress = (userData) => ({
  type: UserActionTypes.GET_CUSTOMER_ADDRESS,
  payload: userData
});

export const getCustomerAddressSuccess = (userData) => ({
  type: UserActionTypes.GET_CUSTOMER_ADDRESS_SUCCESS,
  payload: userData
});

export const getCustomerAddressFailure = (userData) => ({
  type: UserActionTypes.GET_CUSTOMER_ADDRESS_FAILURE,
  payload: userData
});

export const updateCustomerAddressLocal = (data) => ({
  type: UserActionTypes.UPDATE_CUSTOMER_ADDRESS_LOCAL,
  payload: data
});

export const updateStripeCustomerID = (data) => ({
  type: UserActionTypes.UPDATE_STRIPE_CUSTOMERID,
  payload: data
});

export const updateLoggedInError = () => ({
  type: UserActionTypes.UPDATE_ERROR_MESSAGE,
});
