import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { connect } from 'react-redux';
import styles from './productList.module.scss';
import dummy from '../../assets/icons/dummy-image.jpg';
import axios from '../../utils/config/http';
import { GET_PRODUCT_DETAILS } from '../../utils/consts/api';
import IndividualProductDetail from './product-details';
import LoaderDivComponent from '../../components/loader-component/div-loader';
import CartSummaryPage from '../summary-cart';
// import LoginComponent from '../../components/login-component';
// import RedgComponent from '../../components/redg-component';
// import { googleSignInStart } from '../../redux/user/user.actions';
import { addCartItem, addCartItemFailure } from '../../redux/cart/cart.actions';
import { updateProductIdForVideo } from '../../redux/liweShowsList/list.actions';
// import facebook from '../../assets/images/facebook.png';
import AuthComponent from '../../components/Auth';

// eslint-disable-next-line no-shadow
const AllProductComponent = ({ hidecartBlock, webinarDetails, userStatus, addCartItem, updateProductIdForVideo, isCartAdded, addCartItemFailure }) => {
  const handleImageErr = (e) => {
    e.target.src = dummy;
  };
  // const dispatch = useDispatch();
  const [showProductLists, setShowProductList] = useState(true);
  const [prodDetails, setprodDetails] = useState(null);
  const [defaultValueOfparam, setdefaultValueOfparam] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  // const [selectedId, setProductId] = useState(null);
  const [isShowCartOpen, setshowCart] = useState(false);
  const [isLoggedIn, setOpenToLogin] = useState(false);
  const [isBuNowActive, setIsBuyNow] = useState(false);
  // const [openRedg, setopenToRedg] = useState(false);

  const onClickAddToCart = (selectedCategoryData) => {
    setShowLoader(true);
    setIsBuyNow(false);
    if (userStatus === 1) {
      setOpenToLogin(true);
      const obj = {
        merchantId: prodDetails.merchantId,
        productId: prodDetails.id,
        categoryList: selectedCategoryData,
        webinarId: webinarDetails.webinarStatus === 5 ? webinarDetails.webinarId : ''
      };
      addCartItem(obj);
    } else {
      setshowCart(true);
      setOpenToLogin(false);
      setShowLoader(false);
      // setopenToRedg(false);
    }
  };

  const LoadProductDetails = async (productId) => {
    if (productId) {
      try {
        const res = await axios.get(GET_PRODUCT_DETAILS + productId);
        if (res.data.responseCode === 0) {
          res.data.response.commonCategoryFields.forEach((a) => {
            res.data.response.commonAttributes.forEach((b) => {
              if (a.categoryFieldId === b.categoryFieldId) {
                b.type = a.type;
                b.hint = a.hint;
              }
            });
          });
          setprodDetails(res.data.response);
          setdefaultValueOfparam(res.data.response.parameterList[0]);
          setShowLoader(false);
        } else {
          console.log('product detail not found');
        }
      } catch (err) {
        console.log('product detail not found');
      }
    }
  };

  const onClickToShowDetails = (element) => {
    setShowLoader(true);
    setShowProductList(false);
    LoadProductDetails(element.id);
    updateProductIdForVideo(element.id);
  };

  // const googleResponse = (e) => {
  //   console.log('google', e);
  //   const obj = {
  //     loginType: 'social',
  //     facebookId: '',
  //     googleId: e.tokenId,
  //     token: e.accessToken
  //   };
  //   console.log('google 2', obj);
  //   dispatch(googleSignInStart(obj));
  // };

  // const facebookResponse = (e) => {
  //   console.log('facebook', e);
  // };

  useEffect(() => {
    if (userStatus === 1) {
      setOpenToLogin(true);
      // setshowCart(true);
    } else {
      setOpenToLogin(false);
      // setopenToRedg(false);
    }
  }, [userStatus]);

  const hideProductDetails = () => {
    setShowProductList(true);
  };

  useEffect(() => {
    if (isCartAdded) {
      setshowCart(true);
      setShowLoader(false);
      addCartItemFailure();
    }
  }, [isCartAdded]);

  const buySelectedProductAction = (selectedCategoryData) => {
    setShowLoader(true);
    setIsBuyNow(true);
    if (userStatus === 1) {
      setOpenToLogin(true);
      const obj = {
        merchantId: prodDetails.merchantId,
        productId: prodDetails.id,
        categoryList: selectedCategoryData,
        webinarId: webinarDetails.webinarStatus === 5 ? webinarDetails.webinarId : ''
      };
      addCartItem(obj);
    } else {
      setOpenToLogin(false);
      setshowCart(true);
      setShowLoader(false);
    }
  };

  const backToProductDetailsDiv = () => {
    setOpenToLogin(false);
    setshowCart(false);
  };

  return (
    <div>
      {!isShowCartOpen && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{!showProductLists && !showLoader && <AiOutlineArrowLeft style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={hideProductDetails} />}</span>
            <h6 className={styles.prodListHeading}>{ showProductLists ? 'Products' : 'Product Detail'}</h6>
            {/* <AiOutlineCloseCircle style={{ cursor: 'pointer' }} onClick={hideProductList} /> */}
            <span />
          </div>
          <div className="row m-0">
            {showProductLists && (
              <div className="col-12" style={{ height: '450px', overflowY: 'auto' }}>
                <div className={styles.productBox}>
                  {webinarDetails && webinarDetails.productDetails && webinarDetails.productDetails.length > 0
                    && webinarDetails.productDetails.map((element) => (
                      <div key={element.id} className={styles.productItem} onClick={() => onClickToShowDetails(element)}>
                        <div className={styles.listMainBlock} style={{ cursor: 'pointer' }}>
                          <span className={`badge ${styles.custombadge}`}>{element ? `${'$'} ${element.parameterList[0].price}` : 'no price'}</span>
                          <img className={`img-fluid ${styles.prodImage}`} src={element ? element.productImageList[0] : dummy} onError={handleImageErr} alt="No Inage" />
                          <h6 className={styles.prodName}>{element ? element.name : 'no name'}</h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {!showProductLists && showLoader && (
              <div style={{ position: 'relative', height: '450px' }}>
                <LoaderDivComponent />
              </div>
            )}
            {!showProductLists && !showLoader && (
              <div className="col-12 p-0">
                {prodDetails && (
                <div className="row m-0">
                  <IndividualProductDetail prodDetails={prodDetails} defaultValueOfparam={defaultValueOfparam} callAddtoCart={onClickAddToCart} buySelectedProduct={buySelectedProductAction} />
                </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {isShowCartOpen && (
        <>
          {isLoggedIn && <CartSummaryPage hidecartBlock={hidecartBlock} isBuNowActive={isBuNowActive} />}
          {!isLoggedIn && <AuthComponent showProductDetails={backToProductDetailsDiv} />}
        </>
      )}
    </div>
  );
};

AllProductComponent.propTypes = {
  webinarDetails: PropTypes.object,
  // hideProductList: PropTypes.func.isRequired,
  addCartItem: PropTypes.func.isRequired,
  userStatus: PropTypes.number,
  updateProductIdForVideo: PropTypes.func,
  isCartAdded: PropTypes.bool,
  addCartItemFailure: PropTypes.func,
  hidecartBlock: PropTypes.func,
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  isCartAdded: state.cart.isCartAdded
});

export default connect(mapStateToProps, { addCartItem, updateProductIdForVideo, addCartItemFailure })(AllProductComponent);
