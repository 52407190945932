import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHlsPlayer from 'react-hls-player';

export const ProductVideo = ({ currentVideo }) => {
  const [isHLS, setHLSVideo] = useState(true);

  useEffect(() => {
    if (currentVideo.includes('m3u8')) {
      setHLSVideo(true);
    } else {
      setHLSVideo(false);
    }
  }, []);

  return (
    <>
      {isHLS && (
      <ReactHlsPlayer
        src={currentVideo}
        autoPlay
        controls={false}
        id="myVideo"
        width="100%"
        height="100%"
        playsInline
      />
      )}
      {!isHLS && (
        <video playsInline controls={false} style={{ borderRadius: '8px' }} autoPlay={false} width="100%" height="100%" id="subVideo" src={currentVideo} muted>
          Your browser does not support HTML video.
        </video>
      )}
    </>
  );
};
export const ProductHLSVideo = React.memo(ProductVideo);
export default ProductVideo;
ProductVideo.propTypes = {
  currentVideo: PropTypes.string
};
