import React from 'react';
import { Link } from 'react-router-dom';

const FooterComponent = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Link to="/tandc" target="_blank">Terms of Use</Link>
      &nbsp;&nbsp;&nbsp;
      <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
    </div>
  );
};

export default FooterComponent;
