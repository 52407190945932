import React from 'react';
import loaderImg from '../../assets/images/loader.svg';

const LoaderDivComponent = () => {
  return (
    <div style={{ height: '100%' }}>
      <div style={{ position: 'absolute', top: '30%', left: '20%' }}>
        <img src={loaderImg} alt="loaderimage" />
      </div>
    </div>
  );
};
export default LoaderDivComponent;
