import styled from 'styled-components';

export const VideoSection = styled.div`
  width: 100%;
  height: ${(props) => (props.isExpanded ? '93vmax' : '45vh')};
  overflow: ${(props) => (props.isExpanded ? 'hidden' : 'unset')};
`;

export const VideoExpandSection = styled.div`
  height: 90vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
`;

export const WebinarSections = styled.div`
  height: 200px;
  width: 100%;
`;

export const LoaderSection = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
